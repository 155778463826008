define("mentorcam-admin/templates/authenticated/payouts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "62xotBPj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"payouts/list\",[],[[\"@owner\",\"@model\",\"@edit\",\"@add\"],[[34,0],[34,1],\"authenticated.payouts.modal.edit\",\"authenticated.payouts.modal.add\"]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"owner\",\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/payouts.hbs"
    }
  });
  _exports.default = _default;
});