define("mentorcam-admin/templates/components/shared/edit/user-profiles-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "K9NaJKuq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"h4\"],[12],[1,[35,7,[\"name\"]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n  \\n\"],[6,[37,4],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,0,[\"moveUp\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,6],[[35,5],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"moveUp\"],null],[12],[2,\"Up\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"moveUp\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"moveDown\"],null],[12],[2,\"Down\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"moveUp\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"delete\"],null],[12],[2,\"Delete\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n        \"],[18,1,null],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isDisabled\",\"unless\",\"canDown\",\"if\",\"index\",\"not-eq\",\"model\",\"controller\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/edit/user-profiles-row.hbs"
    }
  });
  _exports.default = _default;
});