define("mentorcam-admin/components/shared/model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    record: Ember.computed('modelName', 'store', 'row', function () {
      return this.store.findRecord(this.modelName, this.row);
    })
  });
  _exports.default = _default;
});