define("mentorcam-admin/helpers/stripe-link", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stripeLink = stripeLink;
  function stripeLink([namespace, id]) {
    if (_emberGetConfig.default.environment !== "production") {
      return "https://dashboard.stripe.com/test/" + namespace + "/" + id;
    } else {
      return "https://dashboard.stripe.com/" + namespace + "/" + id;
    }
  }
  var _default = Ember.Helper.helper(stripeLink);
  _exports.default = _default;
});