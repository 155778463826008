define("mentorcam-admin/mixins/read-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    readTimes: Ember.computed(function () {
      return [{
        id: 1,
        display: "1 minute"
      }, {
        id: 2,
        display: "2 minutes"
      }, {
        id: 3,
        display: "3 minutes"
      }, {
        id: 4,
        display: "4 minutes"
      }, {
        id: 5,
        display: "5 minutes"
      }, {
        id: 6,
        display: "6 minutes"
      }, {
        id: 7,
        display: "7 minutes"
      }, {
        id: 8,
        display: "8 minutes"
      }];
    })
  });
  _exports.default = _default;
});