define("mentorcam-admin/components/programs/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/duration-options"], function (_exports, _edit, _durationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_durationOptions.default, {
    classNames: ['programs', 'edit'],
    modelName: "programs",
    identifierOptions: Ember.computed(function () {
      return [{
        id: "accelerator",
        display: "Accelerator Prep"
      }, {
        id: "fundraising",
        display: "Fundraising Starter Pack"
      }, {
        id: "mvp",
        display: "From MVP to PMF"
      }];
    }),
    cycleOptions: Ember.computed(function () {
      return [{
        id: 2,
        display: "60 days"
      }, {
        id: 3,
        display: "90 days"
      }, {
        id: 4,
        display: "120 days"
      }];
    })
  });
  _exports.default = _default;
});