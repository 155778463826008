define("mentorcam-admin/helpers/decode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decode = decode;
  _exports.default = void 0;
  function decode([a]) {
    if (a) {
      return decodeURIComponent(a);
    } else {
      return a;
    }
  }
  var _default = Ember.Helper.helper(decode);
  _exports.default = _default;
});