define("mentorcam-admin/components/user-profiles/traction", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['user-profiles', 'w-3'],
    is_listed: true,
    actions: {
      switchListed() {
        this.set('is_listed', !this.is_listed);
      }
    },
    filterModels: Ember.computed('model', 'model.@each.calculatedTrendingIndex', 'is_listed', function () {
      const is_listed = this.is_listed;
      return this.model.filter(function (item) {
        return item.get('is_listed') === is_listed;
      });
    })
  });
  _exports.default = _default;
});