define("mentorcam-admin/models/promo-code", ["exports", "mentorcam-ember-shared/models/promo-code"], function (_exports, _promoCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _promoCode.default.extend({
    display: Ember.computed('amount', 'is_generic', 'percentage', 'short_id', 'type', function () {
      let string = this.short_id;
      if (this.type === "amount") {
        string = string + " " + "($" + this.amount + ")";
      }
      if (this.type === "percentage") {
        string = string + " " + "(-%" + this.percentage + ")";
      }
      if (this.is_generic) {
        string = string + " - Generic";
      } else {
        string = string + " - Mentor-specific";
      }
      return string;
    })
  });
  _exports.default = _default;
});