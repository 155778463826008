define("mentorcam-admin/components/payouts/list", ["exports", "mentorcam-admin/components/shared/list", "mentorcam-admin/mixins/exports"], function (_exports, _list, _exports2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend(_exports2.default, {
    classNames: ['payouts', 'w-2'],
    firebase: Ember.inject.service(),
    actions: {
      exportPayouts() {
        window.open(this.exportPayoutsUrl, '_blank').focus();
      }
    },
    exportPayoutsUrl: Ember.computed(function () {
      return this.getExport("exportPayouts", "2020-01-01/2024-01-01");
    }),
    async createPayouts() {
      if (this.creatingPayouts) return;
      this.set('creatingPayouts', true);
      try {
        await this.firebase.functions().httpsCallable('createPayouts')({});
      } catch (error) {
        console.error(error);
      } finally {
        this.set('creatingPayouts', false);
      }
    },
    async sendPayoutsReport() {
      if (this.sendingPayoutsReport) return;
      this.set('sendingPayoutsReport', true);
      try {
        await this.firebase.functions().httpsCallable('sendPayoutsReport')({});
      } catch (error) {
        console.error(error);
      } finally {
        this.set('sendingPayoutsReport', false);
      }
    }
  });
  _exports.default = _default;
});