define("mentorcam-admin/models/user", ["exports", "mentorcam-ember-shared/models/user", "mentorcam-admin/mixins/display-attributions", "mentorcam-admin/mixins/device-display", "mentorcam-admin/mixins/timezones"], function (_exports, _user, _displayAttributions, _deviceDisplay, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _user.default.extend(_displayAttributions.default, _deviceDisplay.default, {
    store: Ember.inject.service(),
    display: Ember.computed.reads('email'),
    hasBrokenCalendar: Ember.computed('calendar.{complete,email}', function () {
      if (this.get('calendar.complete') && !this.get('calendar.email')) {
        return true;
      } else {
        return false;
      }
    }),
    hasBrokenSecondaryCalendar: Ember.computed('secondary_calendar.{complete,email}', function () {
      if (this.get('secondary_calendar.complete') && !this.get('secondary_calendar.email')) {
        return true;
      } else {
        return false;
      }
    }),
    getAdminRole: Ember.computed('admin_role', function () {
      switch (this.get('admin_role')) {
        case 'SUPER_ADMIN':
          return 'Super Admin (level 4)';
        case 'REPORT_ADMIN':
          return 'Report Admin (level 3)';
        case 'FINANCE_ADMIN':
          return 'Finance Admin (level 2)';
        case 'SEO_ADMIN':
          return 'SEO Admin (level 1)';
      }
      return 'No Admin Role';
    }),
    isSuperAdmin: Ember.computed('admin_role', function () {
      return this.get('admin_role') === 'SUPER_ADMIN';
    }),
    isFinance: Ember.computed('admin_role', function () {
      return this.get('admin_role') === 'FINANCE_ADMIN';
    }),
    isReport: Ember.computed('admin_role', function () {
      return this.get('admin_role') === 'REPORT_ADMIN';
    }),
    isSEO: Ember.computed('admin_role', function () {
      return this.get('admin_role') === 'SEO_ADMIN';
    }),
    canCreateEditArticles: Ember.computed('admin_role', function () {
      return this.get('admin_role') === 'SEO_ADMIN' || this.get('admin_role') === 'FINANCE_ADMIN' || this.get('admin_role') === 'REPORT_ADMIN' || this.get('admin_role') === 'SUPER_ADMIN';
    }),
    canAccessPayouts: Ember.computed('admin_role', function () {
      return this.get('admin_role') === 'FINANCE_ADMIN' || this.get('admin_role') === 'REPORT_ADMIN' || this.get('admin_role') === 'SUPER_ADMIN';
    }),
    userProfile: Ember.computed('id', function () {
      return this.store.findRecord('user-profile', this.id);
    }),
    displayAuthentication: Ember.computed('authentication', function () {
      return Ember.String.capitalize(this.authentication);
    }),
    displayMobile: Ember.computed('country.dial_code', 'mobile', function () {
      if (this.mobile) {
        return this.get('country.dial_code') + this.mobile;
      } else {
        return null;
      }
    }),
    displayLocation: Ember.computed('country.name', 'ip_metadata.{city,country_name}', function () {
      if (this.ip_metadata) {
        return this.get('ip_metadata.country_name') + "/" + this.get('ip_metadata.city');
      } else {
        return this.get('country.name');
      }
    })
  });
  _exports.default = _default;
});