define("mentorcam-admin/templates/components/messages/edit/assets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "og/yxCBu",
    "block": "{\"symbols\":[\"submit\",\"registerView\",\"unregisterView\",\"isValid\",\"isInvalid\"],\"statements\":[[8,\"inputs/form\",[],[[\"@classNames\",\"@disabled\",\"@complete\"],[\"shared panel\",[34,2],[30,[36,3],[[32,0],\"complete\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navigation\"],[12],[2,\"\\n\\n        \"],[10,\"h1\"],[12],[10,\"strong\"],[12],[2,\"Media Assets\"],[13],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,0,[\"square\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"img\"],[15,\"src\",[34,0,[\"square\"]]],[14,0,\"row full\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,0,[\"portrait\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"img\"],[15,\"src\",[34,0,[\"portrait\"]]],[14,0,\"row full\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,0,[\"animation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"img\"],[15,\"src\",[34,0,[\"animation\"]]],[14,0,\"row full\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n        \"],[8,\"shared/spinner\",[],[[\"@medium\",\"@black\"],[true,true]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"link-to\",[],[[\"@route\",\"@classNames\"],[[34,4],\"shared row button black large cancel\"]],[[\"default\"],[{\"statements\":[[10,\"span\"],[12],[2,\"Cancel\"],[13]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1,2,3,4,5]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"assets\",\"if\",\"processing\",\"action\",\"return\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/messages/edit/assets.hbs"
    }
  });
  _exports.default = _default;
});