define("mentorcam-admin/routes/authenticated/user-profiles/modal/edit/exchange-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render({
        into: 'authenticated.user-profiles.modal'
      });
    },
    model() {
      const model = this.modelFor('authenticated.user-profiles.modal.edit');
      return this.store.findRecord('user', model.id);
    }
  });
  _exports.default = _default;
});