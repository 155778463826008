define("mentorcam-admin/components/topics/list", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['categories', 'w-2'],
    store: Ember.inject.service()
  });
  _exports.default = _default;
});