define("mentorcam-admin/templates/components/shared/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iyV8Yvp7",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"svg\"],[14,0,\"spinner\"],[14,\"width\",\"36px\"],[14,\"height\",\"36px\"],[14,\"viewBox\",\"0 0 36 36\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n\\t\\t\"],[10,\"circle\"],[14,0,\"path\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"2\"],[14,\"stroke-linecap\",\"round\"],[14,\"cx\",\"18\"],[14,\"cy\",\"18\"],[14,\"r\",\"16\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"svg\"],[14,0,\"spinner\"],[14,\"width\",\"28px\"],[14,\"height\",\"28px\"],[14,\"viewBox\",\"0 0 28 28\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n\\t\\t\"],[10,\"circle\"],[14,0,\"path\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"2\"],[14,\"stroke-linecap\",\"round\"],[14,\"cx\",\"14\"],[14,\"cy\",\"14\"],[14,\"r\",\"12\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"svg\"],[14,0,\"spinner\"],[14,\"width\",\"18px\"],[14,\"height\",\"18px\"],[14,\"viewBox\",\"0 0 18 18\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n\\t\\t\"],[10,\"circle\"],[14,0,\"path\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"2\"],[14,\"stroke-linecap\",\"round\"],[14,\"cx\",\"9\"],[14,\"cy\",\"9\"],[14,\"r\",\"8\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"medium\",\"if\",\"large\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/spinner.hbs"
    }
  });
  _exports.default = _default;
});