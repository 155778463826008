define("mentorcam-admin/initializers/route", ["exports", "mentorcam-admin/routes/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'route',
    initialize: function () {}
  };
  _exports.default = _default;
});