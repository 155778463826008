define("mentorcam-admin/helpers/kpi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.kpi = kpi;
  function kpi([input_1, input_2]) {
    if (!input_1 || !input_2) {
      return "0%";
    }
    return Math.round(input_1 / input_2 * 100) + "%";
  }
  var _default = Ember.Helper.helper(kpi);
  _exports.default = _default;
});