define("mentorcam-admin/components/user-profiles/edit/pricing", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['user-profiles', 'username'],
    async didInsertElement() {
      if (!this.get('model.pricing')) {
        await this.setDefault();
      }
    },
    transform(model) {
      if (model.get('pricing.live_call.item_price')) {
        model.set('pricing.live_call.item_price', Number(model.get('pricing.live_call.item_price')));
      }
      if (model.get('pricing.video_message.item_price')) {
        model.set('pricing.video_message.item_price', Number(model.get('pricing.video_message.item_price')));
      }
      if (model.get('pricing.text_message.item_price')) {
        model.set('pricing.text_message.item_price', Number(model.get('pricing.text_message.item_price')));
      }
    },
    actions: {
      async setDefault() {
        await this.setDefault();
      }
    },
    async setDefault() {
      this.set('model.pricing', {
        complete: false,
        live_call: {
          enabled: true,
          item_price: 99.99
        },
        video_message: {
          enabled: true,
          item_price: 29.99
        },
        text_message: {
          enabled: true,
          item_price: 19.99
        }
      });
    },
    videoCallPrices: Ember.computed(function () {
      return [{
        id: 50,
        display: "$50"
      }, {
        id: 100,
        display: "$100"
      }, {
        id: 150,
        display: "$150"
      }, {
        id: 200,
        display: "$200"
      }, {
        id: 250,
        display: "$250"
      }, {
        id: 300,
        display: "$300"
      }, {
        id: 350,
        display: "$350"
      }, {
        id: 400,
        display: "$400"
      }, {
        id: 500,
        display: "$500"
      }];
    })
  });
  _exports.default = _default;
});