define("mentorcam-admin/components/user-profiles/edit/broadcast-messages", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/conversation-types"], function (_exports, _action, _conversationTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_conversationTypes.default, {
    classNames: ['user-profiles', 'broadcast-messages'],
    firebase: Ember.inject.service(),
    type: 'repurchase',
    count: 'loading',
    debug: true,
    async didInsertElement() {
      Ember.run.later(this, async function () {
        await this.readCount();
      }, 200);
    },
    actions: {
      async onConversationTypeChange() {
        Ember.run.later(this, async function () {
          this.set('complete', false);
          await this.readCount();
        }, 200);
      }
    },
    async readCount() {
      try {
        this.set('count', 'loading');
        const input = this.construct();
        input.count = true;
        const {
          data
        } = await this.firebase.functions().httpsCallable('broadcastMessages')(input);
        this.set('count', data.count);
        this.set('total', data.total);
        this.set('returnType', data.type);
        this.set('list', data.list);
      } catch (error) {
        console.error(error);
        this.set('count', 'error');
      }
    },
    construct() {
      return {
        'user-profile': this.get('model.id'),
        text: this.text,
        type: this.type,
        debug: Boolean(this.debug)
      };
    },
    async operation() {
      return this.firebase.functions().httpsCallable('broadcastMessages')(this.construct());
    },
    navigate(data) {
      this.set('complete', true);
      this.set('count', data.count);
      this.set('total', data.total);
      this.set('returnType', data.type);
    }
  });
  _exports.default = _default;
});