define("mentorcam-admin/helpers/should-show-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.shouldShowTime = shouldShowTime;
  function shouldShowTime([date2, date1]) {
    if (!date1) {
      date1 = new Date();
    }
    if (!date1 || !date2) {
      return "years";
    }
    var diff = Math.abs(date1.getTime() - date2.getTime()) / 3600000;
    if (diff < 1) {
      return 'minutes';
    } else if (diff > 48) {
      return "days";
    } else {
      return "hours";
    }
  }
  var _default = Ember.Helper.helper(shouldShowTime);
  _exports.default = _default;
});