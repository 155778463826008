define("mentorcam-admin/templates/authenticated/gift-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kz4ILo4y",
    "block": "{\"symbols\":[],\"statements\":[[8,\"gift-cards/list\",[],[[\"@page\",\"@model\",\"@edit\"],[[32,0,[\"page\"]],[34,0],\"authenticated.gift-cards.modal.edit\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/gift-cards.hbs"
    }
  });
  _exports.default = _default;
});