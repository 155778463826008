define("mentorcam-admin/helpers/isSameDay", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isSameDay = isSameDay;
  function isSameDay([a, b]) {
    if (a && b) {
      return (0, _momentTimezone.default)(a).format('YYYY-MM-DD') === (0, _momentTimezone.default)(b).format('YYYY-MM-DD');
    } else {
      return false;
    }
  }
  var _default = Ember.Helper.helper(isSameDay);
  _exports.default = _default;
});