define("mentorcam-admin/helpers/web-link", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.webLink = webLink;
  function webLink([id, namespace]) {
    if (id && namespace) {
      return _emberGetConfig.default.domainUriPrefix + "/" + namespace + "/" + id;
    } else if (id) {
      return _emberGetConfig.default.domainUriPrefix + "/" + id;
    } else {
      return null;
    }
  }
  var _default = Ember.Helper.helper(webLink);
  _exports.default = _default;
});