define("mentorcam-admin/templates/authenticated/topics/modal/edit/topics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "B+8VWPGS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/edit/topics\",[],[[\"@list\",\"@namespace\",\"@model\",\"@return\",\"@destination\"],[[34,0,[\"topics\"]],\"topics\",[34,0],\"authenticated.topics.modal.edit\",\"authenticated.topics.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/topics/modal/edit/topics.hbs"
    }
  });
  _exports.default = _default;
});