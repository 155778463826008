define("mentorcam-admin/templates/components/shared/edit/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9piQYjdE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"left\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,[34,1]],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"right\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lead\",\"title\",\"if\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/edit/section.hbs"
    }
  });
  _exports.default = _default;
});