define("mentorcam-admin/initializers/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    let app = arguments[1] || arguments[0];
    app.inject('component', 'router', 'router:main');
  }
  var _default = {
    name: 'router',
    initialize: initialize
  };
  _exports.default = _default;
});