define("mentorcam-admin/components/users/edit/create-card", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['users', 'create-card'],
    firebase: Ember.inject.service(),
    async operation() {
      return this.firebase.functions().httpsCallable('createCard')({
        user: this.get('model.id'),
        token: this.get('token')
      });
    },
    navigate() {
      this.get('router').transitionTo("authenticated.users.modal.edit");
    }
  });
  _exports.default = _default;
});