define("mentorcam-admin/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fp8zggs3",
    "block": "{\"symbols\":[],\"statements\":[[8,\"login\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/login.hbs"
    }
  });
  _exports.default = _default;
});