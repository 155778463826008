define("mentorcam-admin/helpers/conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.conversion = conversion;
  _exports.default = void 0;
  function conversion([first, second]) {
    return Math.round(second / first * 100) + "%";
  }
  var _default = Ember.Helper.helper(conversion);
  _exports.default = _default;
});