define("mentorcam-admin/templates/authenticated/users/modal/edit/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Af+ci4Mt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"users/edit/email\",[],[[\"@model\",\"@return\"],[[34,0],\"authenticated.users.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/users/modal/edit/email.hbs"
    }
  });
  _exports.default = _default;
});