define("mentorcam-admin/components/gift-cards/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/public-user-profiles", "mentorcam-admin/mixins/duration-options"], function (_exports, _edit, _publicUserProfiles, _durationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_publicUserProfiles.default, _durationOptions.default, {
    classNames: ['gift-cards', 'edit'],
    modelName: "gift-cards"
  });
  _exports.default = _default;
});