define("mentorcam-admin/mixins/timezones", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deprecatedTimezones = deprecatedTimezones;
  _exports.getName = getName;
  _exports.getReadableTimestamp = getReadableTimestamp;
  _exports.timezones = timezones;
  function getReadableTimestamp(timestamp, time_zone, linebreak) {
    if (!time_zone) {
      time_zone = "America/Los_Angeles";
    }
    const time = _momentTimezone.default.tz(timestamp, time_zone);
    const name = getName(time_zone);
    if (linebreak) {
      return `${time.format("ddd, MMM D")} - ${time.format("h:mma").toLowerCase()}<br><span class="tiny">${name}</span>`;
    } else {
      return time.format("ddd, MMM D") + " - " + time.format("h:mma").toLowerCase() + " (" + name + ")";
    }
  }
  function getName(id) {
    return names[id];
  }
  function timezones() {
    return Object.keys(names).map(key => ({
      id: key,
      display: names[key]
    }));
  }
  const names = {
    "America/Los_Angeles": "Pacific Time",
    "America/Denver": "Mountain Time",
    "America/Chicago": "Central Time",
    "America/New_York": "Eastern Time",
    "America/Adak": "America/Adak",
    "America/Anchorage": "Alaska Time",
    "America/Argentina/Buenos_Aires": "Buenos Aires Time",
    "America/Asuncion": "Asuncion Time",
    "America/Bogota": "Bogota, Jamaica, Lima Time",
    "America/Campo_Grande": "America/Campo Grande",
    "America/Caracas": "Caracas Time",
    "America/Godthab": "America/Godthab",
    "America/Goose_Bay": "Atlantic Time",
    "America/Guatemala": "Saskatchewan, Guatemala, Costa Rica Time",
    "America/Havana": "America/Havana",
    "America/Mazatlan": "America/Mazatlan",
    "America/Mexico_City": "Mexico City Time",
    "America/Montevideo": "Montevideo Time",
    "America/Miquelon": "America/Miquelon",
    "America/Noronha": "America/Noronha",
    "America/Phoenix": "Arizona, Yukon Time",
    "America/Santiago": "Santiago Time",
    "America/Santa_Isabel": "America/Santa Isabel",
    "America/Santo_Domingo": "Atlantic Standard Time",
    "America/Sao_Paulo": "Brasilia Time",
    "America/St_Johns": "Newfoundland Time",
    "Africa/Cairo": "Africa/Cairo",
    "Africa/Johannesburg": "Central Africa Time",
    "Africa/Lagos": "West Africa Time",
    "Africa/Windhoek": "Africa/Windhoek",
    "Asia/Amman": "Jordan Time",
    "Asia/Baghdad": "Baghdad, East Africa Time",
    "Asia/Baku": "Asia/Baku",
    "Asia/Beirut": "Lebanon Time",
    "Asia/Damascus": "Syria Time",
    "Asia/Dhaka": "Asia/Dhaka",
    "Asia/Dubai": "Dubai Time",
    "Asia/Gaza": "Asia/Gaza",
    "Asia/Irkutsk": "Asia/Irkutsk",
    "Asia/Jakarta": "Indochina Time",
    "Asia/Jerusalem": "Israel Time",
    "Asia/Kabul": "Kabul Time",
    "Asia/Kamchatka": "Pacific/Majuro",
    "Asia/Karachi": "Pakistan, Maldives Time",
    "Asia/Kathmandu": "Kathmandu Time",
    "Asia/Kolkata": "India, Sri Lanka Time",
    "Asia/Krasnoyarsk": "Krasnoyarsk Time",
    "Asia/Omsk": "Asia/Omsk",
    "Asia/Rangoon": "Asia/Rangoon",
    "Asia/Shanghai": "China, Singapore, Perth",
    "Asia/Tehran": "Tehran Time",
    "Asia/Tokyo": "Japan, Korea Time",
    "Asia/Vladivostok": "Asia/Vladivostok",
    "Asia/Yakutsk": "Asia/Yakutsk",
    "Asia/Yekaterinburg": "Yekaterinburg Time",
    "Asia/Yerevan": "Asia/Yerevan",
    "Atlantic/Azores": "Azores Time",
    "Atlantic/Cape_Verde": "Cape Verde Time",
    "Australia/Adelaide": "Adelaide Time",
    "Australia/Brisbane": "Brisbane Time",
    "Australia/Darwin": "Australia/Darwin",
    "Australia/Eucla": "Australia/Eucla",
    "Australia/Lord_Howe": "Australia/Lord Howe",
    "Australia/Perth": "Australia/Perth",
    "Australia/Sydney": "Sydney, Melbourne Time",
    "UTC": "UTC Time",
    "Europe/Berlin": "Central European Time",
    "Europe/Helsinki": "Eastern European Time",
    "Europe/London": "UK, Ireland, Lisbon Time",
    "Europe/Minsk": "Minsk Time",
    "Europe/Moscow": "Moscow Time",
    "Europe/Istanbul": "Turkey Time",
    "Pacific/Apia": "Pacific/Apia",
    "Pacific/Auckland": "Auckland Time",
    "Pacific/Chatham": "Pacific/Chatham",
    "Pacific/Easter": "Pacific/Easter",
    "Pacific/Fiji": "Pacific/Fiji",
    "Pacific/Gambier": "Pacific/Gambier",
    "Pacific/Honolulu": "Hawaii Time",
    "Pacific/Kiritimati": "Pacific/Kiritimati",
    "Pacific/Majuro": "Pacific/Majuro",
    "Pacific/Marquesas": "Pacific/Marquesas",
    "Pacific/Norfolk": "Pacific/Norfolk",
    "Pacific/Noumea": "Pacific/Noumea",
    "Pacific/Pago_Pago": "Pacific/Pago Pago",
    "Pacific/Pitcairn": "Pacific/Pitcairn",
    "Pacific/Tarawa": "Pacific/Tarawa",
    "Pacific/Tongatapu": "Pacific/Tongatapu"
  };
  function deprecatedTimezones() {
    return [{
      id: "PDT",
      name: "Pacific Daylight Time",
      display: "USA/Pacific Daylight Time",
      offset: -7
    }, {
      id: "PST",
      name: "Pacific Standard Time",
      display: "USA/Pacific Standard Time",
      offset: -8
    }, {
      id: "EDT",
      name: "Eastern Daylight Time",
      display: "USA/Eastern Daylight Time",
      offset: -4
    }, {
      id: "EST",
      name: "Eastern Standard Time",
      display: "USA/Eastern Standard Time",
      offset: -5
    }, {
      id: "MDT",
      name: "Mountain Daylight Time",
      display: "USA/Mountain Daylight Time",
      offset: -6
    }, {
      id: "MST",
      name: "Mountain Standard Time",
      display: "USA/Mountain Standard Time",
      offset: -7
    }, {
      id: "CDT",
      name: "Central Daylight Time",
      display: "USA/Central Daylight Time",
      offset: -5
    }, {
      id: "CST",
      name: "Central Standard Time",
      display: "USA/Central Standard Time",
      offset: -6
    }, {
      id: "AKDT",
      name: "Alaska Daylight Time",
      display: "USA/Alaska Daylight Time",
      offset: -8
    }, {
      id: "AKST",
      name: "Alaska Standard Time",
      display: "USA/Alaska Standard Time",
      offset: -9
    }, {
      id: "HST",
      name: "Hawaii Time",
      display: "USA/Hawaii Time",
      offset: -10
    }, {
      id: "NDT",
      name: "Newfoundland Daylight Time",
      display: "USA/Newfoundland Daylight Time",
      offset: -2.5
    }, {
      id: "NST",
      name: "Newfoundland Standard Time",
      display: "USA/Newfoundland Standard Time",
      offset: -3.5
    }, {
      id: "EGST",
      name: "Eastern Greenland Summer Time",
      display: "USA/Eastern Greenland Summer Time",
      offset: 0
    }, {
      id: "EGT",
      name: "East Greenland Time",
      display: "USA/East Greenland Time",
      offset: -1
    }, {
      id: "WEST",
      name: "Western European Summer Time",
      display: "Europe/Western Summer Time",
      offset: 1
    }, {
      id: "WET",
      name: "Western European Time",
      display: "Europe/Western Time",
      offset: 0
    }, {
      id: "EEST",
      name: "Eastern European Summer Time",
      display: "Europe/Eastern Summer Time",
      offset: 3
    }, {
      id: "EET",
      name: "Eastern European Time",
      display: "Europe/Eastern Time",
      offset: 2
    }, {
      id: "CEST",
      name: "Central European Summer Time",
      display: "Europe/Central Summer Time",
      offset: 2
    }, {
      id: "CET",
      name: "Central European Time",
      display: "Europe/Central Time",
      offset: 1
    }, {
      id: "BST",
      name: "British Summer Time",
      display: "Europe/British Summer Time",
      offset: 1
    }, {
      id: "IST",
      name: "Irish Standard Time",
      display: "Europe/Irish Standard Time",
      offset: 1
    }, {
      id: "MSD",
      name: "Moscow Daylight Time",
      display: "Europe/Moscow Daylight Time",
      offset: 4
    }, {
      id: "MSK",
      name: "Moscow Standard Time",
      display: "Europe/Moscow Standard Time",
      offset: 4
    }, {
      id: "ACDT",
      name: "Australian Central Daylight Time",
      display: "Australia/Central Daylight Time",
      offset: 10.5
    }, {
      id: "ACST",
      name: "Australian Central Standard Time",
      display: "Australia/Central Standard Time",
      offset: 9.5
    }, {
      id: "AEDT",
      name: "Australian Eastern Daylight Time",
      display: "Australia/Eastern Daylight Time",
      offset: 11
    }, {
      id: "AEST",
      name: "Australian Eastern Standard Time",
      display: "Australia/Eastern Standard Time",
      offset: 10
    }, {
      id: "AWDT",
      name: "Australian Western Daylight Time",
      display: "Australia/Western Daylight Time",
      offset: 9
    }, {
      id: "AWST",
      name: "Australian Western Standard Time",
      display: "Australia/Western Standard Time",
      offset: 8
    }, {
      id: "AST",
      name: "Arabia Standard Time",
      display: "Asia/Arabia Standard Time",
      offset: 3
    }, {
      id: "IDT",
      name: "Israel Daylight Time",
      display: "Asia/Israel Daylight Time",
      offset: 3
    }, {
      id: "AZT",
      name: "Azerbaijan Time",
      display: "Asia/Azerbaijan Time",
      offset: 4
    }, {
      id: "GST",
      name: "Gulf Standard Time",
      display: "Asia/Gulf Standard Time",
      offset: 4
    }, {
      id: "AFT",
      name: "Afghanistan Time",
      display: "Asia/Afghanistan Time",
      offset: 4.5
    }, {
      id: "MVT",
      name: "Maldives Time",
      display: "Asia/Maldives Time",
      offset: 5
    }, {
      id: "PKT",
      name: "Pakistan Time",
      display: "Asia/Pakistan Time",
      offset: 5
    }, {
      id: "UZT",
      name: "Uzbekistan Time",
      display: "Asia/Uzbekistan Time",
      offset: 5
    }, {
      id: "IST",
      name: "India Time",
      display: "Asia/India Time",
      offset: 5.5
    }, {
      id: "MMT",
      name: "Myanmar Time",
      display: "Asia/Myanmar Time",
      offset: 6.5
    }, {
      id: "ICT",
      name: "Indochina Time",
      display: "Asia/Indochina Time",
      offset: 7
    }, {
      id: "WIB",
      name: "Western Indonesian Time",
      display: "Asia/Western Indonesian Time",
      offset: 7
    }, {
      id: "HKT",
      name: "Hong Kong Time",
      display: "Asia/Hong Kong Time",
      offset: 8
    }, {
      id: "WITA",
      name: "Central Indonesian Time",
      display: "Asia/Central Indonesian Time",
      offset: 8
    }, {
      id: "MYT",
      name: "Malaysia Time",
      display: "Asia/Malaysia Time",
      offset: 8
    }, {
      id: "SGT",
      name: "Singapore Time",
      display: "Asia/Singapore Time",
      offset: 8
    }, {
      id: "PHT",
      name: "Philippine Time",
      display: "Asia/Philippine Time",
      offset: 8
    }, {
      id: "WIT",
      name: "Eastern Indonesian Time",
      display: "Asia/Eastern Indonesian Time",
      offset: 9
    }, {
      id: "I",
      name: "India Time Zone",
      display: "Asia/India Time Zone",
      offset: 9
    }, {
      id: "JST",
      name: "Japan Time",
      display: "Asia/Japan Time",
      offset: 9
    }, {
      id: "KST",
      name: "Korea Time",
      display: "Asia/Korea Time",
      offset: 9
    }, {
      id: "PETST",
      name: "Kamchatka Summer Time",
      display: "Asia/Kamchatka Summer Time",
      offset: 12
    }, {
      id: "PETT",
      name: "Kamchatka Time",
      display: "Asia/Kamchatka Time",
      offset: 12
    }, {
      id: "PET",
      name: "Peru Time",
      display: "South America/Peru Time",
      offset: -5
    }, {
      id: "COT",
      name: "Colombia Time",
      display: "South America/Colombia Time",
      offset: -5
    }, {
      id: "ECT",
      name: "Ecuador Time",
      display: "South America/Ecuador Time",
      offset: -5
    }, {
      id: "VET",
      name: "Venezuelan Time",
      display: "South America/Venezuelan Time",
      offset: -4.5
    }, {
      id: "PYT",
      name: "Paraguay Time",
      display: "South America/Paraguay Time",
      offset: -4
    }, {
      id: "CLT",
      name: "Chile Time",
      display: "South America/Chile Time",
      offset: -4
    }, {
      id: "AMT",
      name: "Amazon Time",
      display: "South America/Amazon Time",
      offset: -4
    }, {
      id: "BOT",
      name: "Bolivia Time",
      display: "South America/Bolivia Time",
      offset: -4
    }, {
      id: "ART",
      name: "Argentina Time",
      display: "South America/Argentina Time",
      offset: -3
    }, {
      id: "BRT",
      name: "Brasília Time",
      display: "South America/Brasília Time",
      offset: -3
    }, {
      id: "BRST",
      name: "Brasília Summer Time",
      display: "South America/Brasília Summer Time",
      offset: -2
    }, {
      id: "CLST",
      name: "Chile Summer Time",
      display: "South America/Chile Summer Time",
      offset: -3
    }, {
      id: "UYT",
      name: "Uruguay Time",
      display: "South America/Uruguay Time",
      offset: -3
    }, {
      id: "UYST",
      name: "Uruguay Summer Time",
      display: "South America/Uruguay Summer Time",
      offset: -2
    }, {
      id: "PYST",
      name: "Paraguay Summer Time",
      display: "South America/Paraguay Summer Time",
      offset: -3
    }, {
      id: "FJST",
      name: "Fiji Summer Time",
      display: "Pacific/Fiji Summer Time",
      offset: 13
    }, {
      id: "FJT",
      name: "Fiji Time",
      display: "Pacific/Fiji Time",
      offset: 12
    }, {
      id: "NZDT",
      name: "New Zealand Daylight Time",
      display: "Pacific/New Zealand Daylight Time",
      offset: 13
    }, {
      id: "NZST",
      name: "New Zealand Standard Time",
      display: "Pacific/New Zealand Standard Time",
      offset: 12
    }, {
      id: "NCT",
      name: "New Caledonia Time",
      display: "Pacific/New Caledonia Time",
      offset: 11
    }, {
      id: "PGT",
      name: "Papua New Guinea Time",
      display: "Pacific/Papua New Guinea Time",
      offset: 10
    }, {
      id: "EASST",
      name: "Easter Island Summer Time",
      display: "Pacific/Easter Island Summer Time",
      offset: -5
    }, {
      id: "EAST",
      name: "Easter Island Standard Time",
      display: "Pacific/Easter Island Standard Time",
      offset: -6
    }, {
      id: "TAHT",
      name: "Tahiti Time",
      display: "Pacific/Tahiti Time",
      offset: -10
    }, {
      id: "WT",
      name: "Western Sahara Standard Time",
      display: "Africa/Western Sahara Standard Time",
      offset: 0
    }, {
      id: "WAT",
      name: "West Africa Time",
      display: "Africa/West Africa Time",
      offset: 1
    }, {
      id: "WST",
      name: "Western Sahara Summer Time",
      display: "Africa/Western Sahara Summer Time",
      offset: 1
    }, {
      id: "SAST",
      name: "South Africa Standard Time",
      display: "Africa/South Africa Standard Time",
      offset: 2
    }, {
      id: "WAST",
      name: "West Africa Summer Time",
      display: "Africa/West Africa Summer Time",
      offset: 2
    }, {
      id: "CAT",
      name: "Central Africa Time",
      display: "Africa/Central Africa Time",
      offset: 2
    }, {
      id: "EAT",
      name: "Eastern Africa Time",
      display: "Africa/Eastern Africa Time",
      offset: 3
    }, {
      id: "MUT",
      name: "Mauritius Time",
      display: "Africa/Mauritius Time",
      offset: 4
    }, {
      id: "SCT",
      name: "Seychelles Time",
      display: "Africa/Seychelles Time",
      offset: 4
    }, {
      id: "AZOST",
      name: "Azores Summer Time",
      display: "Atlantic/Azores Summer Time",
      offset: 0
    }, {
      id: "AZOT",
      name: "Azores Time",
      display: "Atlantic/Azores Time",
      offset: -1
    }];
  }
});