define("mentorcam-admin/templates/authenticated/report/modal/range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5bl0tKSH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"report/range\",[],[[\"@model\",\"@destination\"],[[34,0],\"authenticated.report\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/report/modal/range.hbs"
    }
  });
  _exports.default = _default;
});