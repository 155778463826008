define("mentorcam-admin/routes/authenticated/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    async model() {
      await this.session.invalidate();
      this.transitionTo('login');
      window.location.reload();
    }
  });
  _exports.default = _default;
});