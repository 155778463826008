define("mentorcam-admin/models/push-notification", ["exports", "mentorcam-ember-shared/models/push-notification"], function (_exports, _pushNotification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pushNotification.default.extend({
    changeTracker: {
      trackHasMany: true,
      except: ["metadata"]
    }
  });
  _exports.default = _default;
});