define("mentorcam-admin/helpers/dailyco-join-url", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dailycoJoinUrl = dailycoJoinUrl;
  _exports.default = void 0;
  function dailycoJoinUrl([model, namespace]) {
    try {
      const token = model.get('dailyco').tokens[namespace];
      let domain = _emberGetConfig.default.domainUriPrefix;
      if (window.location.href.indexOf('localhost') !== -1) {
        domain = "http://localhost:3000";
      }
      return domain + "/join/" + model.id + "/" + token;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  var _default = Ember.Helper.helper(dailycoJoinUrl);
  _exports.default = _default;
});