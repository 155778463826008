define("mentorcam-admin/components/shared/multiple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['shared', 'multiple-select'],
    computedModels: Ember.computed('models', 'value', 'models.length', 'value.length', function () {
      const models = this.models;
      const value = this.value;
      if (typeof models === 'object') {
        models.forEach(item => {
          if (item.set) {
            const status = value.findBy('id', item.id) ? true : false;
            item.set('selected', status);
          } else {
            if (!value) {
              set(item, 'selected', false);
            } else {
              set(item, 'selected', value.indexOf(item.id) !== -1);
            }
          }
        });
      }
      return this.models;
    }),
    actions: {
      change() {
        let array = this.get('models').filter(function (item) {
          return item.selected === true;
        });
        if (!this.get('models.firstObject').store) {
          array = array.map(function (item) {
            return item.id;
          });
        }
        this.get('value').setObjects(array);
      }
    }
  });
  _exports.default = _default;
});