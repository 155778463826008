define("mentorcam-admin/helpers/divide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.divide = divide;
  function divide([a, b]) {
    if (a && b) {
      return (a / b).toFixed(2);
    } else {
      return 'N/A';
    }
  }
  var _default = Ember.Helper.helper(divide);
  _exports.default = _default;
});