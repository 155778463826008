define("mentorcam-admin/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BWglB/7r",
    "block": "{\"symbols\":[],\"statements\":[[10,\"title\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\"],[10,\"meta\"],[14,3,\"description\"],[15,\"content\",[34,0,[\"description\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/head.hbs"
    }
  });
  _exports.default = _default;
});