define("mentorcam-admin/templates/authenticated/conversations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "y0Tbveu7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"conversations/list\",[],[[\"@is_listed\",\"@page\",\"@model\",\"@edit\",\"@add\"],[[32,0,[\"is_listed\"]],[32,0,[\"page\"]],[34,0],\"authenticated.conversations.modal.edit\",\"authenticated.conversations.modal.add\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/conversations.hbs"
    }
  });
  _exports.default = _default;
});