define("mentorcam-admin/templates/authenticated/push-notifications/modal/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nx1AFvsU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"push-notifications/edit\",[],[[\"@embedded\",\"@model\",\"@destination\"],[true,[34,0],\"authenticated.push-notifications\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/push-notifications/modal/add.hbs"
    }
  });
  _exports.default = _default;
});