define("mentorcam-admin/components/promo-codes/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['promo-codes', 'edit'],
    modelName: "promo-codes",
    typeOptions: Ember.computed(function () {
      return [{
        id: "amount",
        display: "Amount"
      }, {
        id: "percentage",
        display: "Percentage"
      }];
    }),
    validityOptions: Ember.computed(function () {
      return [{
        id: 1,
        display: "1 day"
      }, {
        id: 7,
        display: "7 days"
      }, {
        id: 14,
        display: "14 days"
      }, {
        id: 30,
        display: "30 days"
      }, {
        id: 90,
        display: "90 days"
      }, {
        id: 180,
        display: "180 days"
      }, {
        id: 360,
        display: "360 days"
      }];
    })
  });
  _exports.default = _default;
});