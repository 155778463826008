define("mentorcam-admin/helpers/unique", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.unique = unique;
  function unique([first, second]) {
    return Math.round(first / second * 10) / 10;
  }
  var _default = Ember.Helper.helper(unique);
  _exports.default = _default;
});