define("mentorcam-admin/routes/authenticated/referral-credits/modal/edit/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render({
        into: 'authenticated.referral-credits.modal'
      });
    }
  });
  _exports.default = _default;
});