define("mentorcam-admin/templates/components/inputs/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kUiWFM/a",
    "block": "{\"symbols\":[\"model\",\"index\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"option\"],[15,2,[32,1,[\"id\"]]],[15,\"selected\",[30,[36,1],[[35,0],[32,1,[\"id\"]]],null]],[12],[1,[32,1,[\"display\"]]],[13],[2,\"\\n\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"eq\",\"models\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/inputs/select.hbs"
    }
  });
  _exports.default = _default;
});