define("mentorcam-admin/components/subscriptions/create", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/identifier-options"], function (_exports, _action, _identifierOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_identifierOptions.default, {
    classNames: ['subscriptions', 'create'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    async operation() {
      var identifier = this.identifier;
      if (identifier === '0') {
        identifier = 0;
      } else if (identifier === '1') {
        identifier = 1;
      } else if (identifier === '2') {
        identifier = 2;
      }
      return this.firebase.functions().httpsCallable('createSubscription')({
        owner: this.model.id,
        user_profiles: this.user_profiles ? this.user_profiles : [],
        identifier: identifier,
        subscription_id: this.subscription_id
      });
    },
    navigate(data) {
      this.router.transitionTo("authenticated.subscriptions.modal.edit", data.subscription);
    }
  });
  _exports.default = _default;
});