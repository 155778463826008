define("mentorcam-admin/routes/authenticated/calls", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    pagination: 50,
    queryParams: {
      is_fulfilled: {
        refreshModel: true
      },
      is_deleted: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      owner: {
        refreshModel: true
      },
      recipient: {
        refreshModel: true
      },
      subscription: {
        refreshModel: true
      },
      conversation: {
        refreshModel: true
      },
      is_cancelled: {
        refreshModel: true
      },
      timestamp: {
        refreshModel: true
      }
    },
    model(params) {
      const timestamp = params.timestamp;
      const is_cancelled = params.is_cancelled;
      var extension;
      var sort = true;
      if (is_cancelled) {
        sort = {
          param: "cancelled_at",
          by: "desc"
        };
      }
      if (timestamp) {
        delete params.timestamp;
        if (timestamp === "past") {
          extension = {
            query: "timestamp",
            operator: "<",
            value: new Date().getTime()
          };
          sort = {
            param: "timestamp",
            by: "desc"
          };
        } else {
          extension = {
            query: "timestamp",
            operator: ">",
            value: new Date().getTime()
          };
          sort = {
            param: "timestamp"
          };
        }
      }
      return this.store.query('call', this.transcodeParams(params, sort, extension));
    }
  });
  _exports.default = _default;
});