define("mentorcam-admin/mixins/development", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    development: Ember.computed(function () {
      if (_emberGetConfig.default.environment === "development") {
        return true;
      } else {
        return false;
      }
    })
  });
  _exports.default = _default;
});