define("mentorcam-admin/templates/authenticated/user-profile-views/modal/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Zw3TL+zX",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-profile-views/edit\",[],[[\"@model\",\"@destination\"],[[34,0],\"authenticated.user-profile-views\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/user-profile-views/modal/edit.hbs"
    }
  });
  _exports.default = _default;
});