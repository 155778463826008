define("mentorcam-admin/models/conversation", ["exports", "mentorcam-ember-shared/models/conversation", "mentorcam-admin/mixins/device-display"], function (_exports, _conversation, _deviceDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _conversation.default.extend(_deviceDisplay.default, {
    queryId: Ember.computed('id', function () {
      return 'conversation:' + this.id;
    })
  });
  _exports.default = _default;
});