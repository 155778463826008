define("mentorcam-admin/templates/components/shared/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CBAiGDH4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"line\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/loader.hbs"
    }
  });
  _exports.default = _default;
});