define("mentorcam-admin/templates/components/inputs/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H8WpU0bd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],[[32,0],\"submit\"],null],[30,[36,2],[[32,0],\"registerView\"],null],[30,[36,2],[[32,0],\"unregisterView\"],null],[35,1],[35,0]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isInvalid\",\"isValid\",\"action\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/inputs/form.hbs"
    }
  });
  _exports.default = _default;
});