define("mentorcam-admin/adapters/cloud-firestore", ["exports", "ember-cloud-firestore-adapter/adapters/cloud-firestore"], function (_exports, _cloudFirestore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cloudFirestore.default;
    }
  });
});