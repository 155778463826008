define("mentorcam-admin/templates/authenticated/users/modal/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gj9PU6zL",
    "block": "{\"symbols\":[],\"statements\":[[8,\"users/search\",[],[[\"@destination\"],[\"authenticated.users\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/users/modal/search.hbs"
    }
  });
  _exports.default = _default;
});