define("mentorcam-admin/templates/authenticated/session-logs/modal/edit/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kkLXB7O6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/delete\",[],[[\"@model\",\"@label\",\"@return\",\"@destination\"],[[34,0],[34,0,[\"id\"]],\"authenticated.session-logs.modal.edit\",\"authenticated.session-logs\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/session-logs/modal/edit/delete.hbs"
    }
  });
  _exports.default = _default;
});