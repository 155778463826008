define("mentorcam-admin/templates/authenticated/message-drafts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zrQpKbCc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],\"text\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"message-drafts/texts\",[],[[\"@page\",\"@model\",\"@edit\"],[[32,0,[\"page\"]],[34,0],\"authenticated.message-drafts.modal.edit\"]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"message-drafts/list\",[],[[\"@page\",\"@model\",\"@edit\"],[[32,0,[\"page\"]],[34,0],\"authenticated.message-drafts.modal.edit\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"content_type\",\"eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/message-drafts.hbs"
    }
  });
  _exports.default = _default;
});