define("mentorcam-admin/components/shared/content/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['shared', 'editor'],
    actions: {
      async save() {
        if (this.processing) {
          return;
        }
        this.set('processing', true);
        try {
          this.model.set('content', this.html);
          await this.model.save();
        } catch (error) {
          this.set('error', error);
          console.error(error);
        } finally {
          this.set('processing', false);
        }
      },
      onChange(html) {
        this.set('html', html);
      }
    }
  });
  _exports.default = _default;
});