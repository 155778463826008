define("mentorcam-admin/components/payments/list", ["exports", "mentorcam-admin/components/shared/list", "mentorcam-admin/mixins/exports"], function (_exports, _list, _exports2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend(_exports2.default, {
    classNames: ['payments', 'w-2'],
    actions: {
      exportPayments() {
        window.open(this.exportPaymentsUrl, '_blank').focus();
      }
    },
    exportPaymentsUrl: Ember.computed(function () {
      return this.getExport("exportPayments", "2020-01-01/2024-01-01");
    })
  });
  _exports.default = _default;
});