define("mentorcam-admin/templates/authenticated/user-profiles/modal/sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V7HAfvQ7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-profiles/sync\",[],[[\"@model\",\"@destination\"],[[34,0],\"authenticated.user-profiles\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/user-profiles/modal/sync.hbs"
    }
  });
  _exports.default = _default;
});