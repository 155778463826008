define("mentorcam-admin/templates/authenticated/content-articles/modal/edit/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r7boQ5CT",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/edit/categories\",[],[[\"@list\",\"@namespace\",\"@model\",\"@return\",\"@destination\"],[[34,0,[\"categories\"]],\"categories\",[34,0],\"authenticated.content-articles.modal.edit\",\"authenticated.content-articles.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/content-articles/modal/edit/categories.hbs"
    }
  });
  _exports.default = _default;
});