define("mentorcam-admin/controllers/authenticated/message-drafts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: {
      recipient: {
        type: "string"
      },
      owner: {
        type: "string"
      },
      is_owner: {
        type: "boolean"
      },
      content_type: {
        type: 'string'
      },
      page: {
        type: "number"
      },
      has_message: {
        type: "boolean"
      },
      conversation: {
        type: "string"
      }
    }
  });
  _exports.default = _default;
});