define("mentorcam-admin/routes/authenticated/admins", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    model() {
      return this.store.query('user', this.transcodeParams({
        is_admin: true
      }));
    }
  });
  _exports.default = _default;
});