define("mentorcam-admin/models/content-article", ["exports", "mentorcam-ember-shared/models/content-article"], function (_exports, _contentArticle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _contentArticle.default.extend({
    display: Ember.computed.reads('title')
  });
  _exports.default = _default;
});