define("mentorcam-admin/templates/components/shared/badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AnzFrar/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,0,[\"displayMobile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"circle mobile\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[35,0,[\"device\",\"platform\"]],\"ios\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,0,[\"device\",\"token\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"circle push\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[35,0,[\"device\",\"platform\"]],\"android\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,0,[\"device\",\"id\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"circle push\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"if\",\"eq\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/badges.hbs"
    }
  });
  _exports.default = _default;
});