define("mentorcam-admin/components/shared/edit/display-filter", ["exports", "mentorcam-admin/components/shared/edit/display-row"], function (_exports, _displayRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _displayRow.default.extend({
    modelName: "filter"
  });
  _exports.default = _default;
});