define("mentorcam-admin/routes/authenticated/messages", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    queryParams: {
      is_seen: {
        refreshModel: true
      },
      is_deleted: {
        refreshModel: true
      },
      content_type: {
        refreshModel: true
      },
      triggered: {
        refreshModel: true
      },
      has_next_message: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      conversation: {
        refreshModel: true
      },
      owner: {
        refreshModel: true
      },
      is_owner: {
        refreshModel: true
      },
      recipient: {
        refreshModel: true
      }
    },
    model(params) {
      return this.store.query('message', this.transcodeParams(params, true));
    }
  });
  _exports.default = _default;
});