define("mentorcam-admin/templates/components/shared/edit/content-articles-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OwPfebrP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[35,2],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"moveUp\"],null],[12],[2,\"Up\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"moveDown\"],null],[12],[2,\"Down\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"delete\"],null],[12],[2,\"Delete\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"index\",\"not-eq\",\"if\",\"canDown\",\"isDisabled\",\"unless\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/edit/content-articles-row.hbs"
    }
  });
  _exports.default = _default;
});