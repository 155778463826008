define("mentorcam-admin/helpers/subscription-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.subscriptionTypes = subscriptionTypes;
  function subscriptionTypes([identifier]) {
    const array = ["Mentorcam Starter", "Mentorcam Pro", "Mentorcam Premium"];
    return array[identifier];
  }
  var _default = Ember.Helper.helper(subscriptionTypes);
  _exports.default = _default;
});