define("mentorcam-admin/components/shared/content/rich-text-editor", ["exports", "quill", "quill-paste-smart"], function (_exports, _quill, _quillPasteSmart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['shared', 'rich-text-editor'],
    classNameBindings: ['active'],
    defaultText: "<h2>Title comes here</h2><p>Body comes here</p>",
    init() {
      this._super();
      if (!this.value || this.value === "") {
        this.set('value', this.defaultText);
      } else {
        this.initial = this.value.split("<p><br></p>").join("");
      }
    },
    didInsertElement() {
      this._super();
      const id = 'editor-' + this.element.id;
      const toolbarOptions = [['bold', 'italic'], [{
        'header': [2, 3, false]
      }], ['blockquote'], [{
        'list': 'ordered'
      }, {
        'list': 'bullet'
      }], [{
        'indent': '-1'
      }, {
        'indent': '+1'
      }], ['link'], ['clean']];
      const quill = new _quill.default('#' + id, {
        theme: 'bubble',
        placeholder: 'Type your text here...',
        modules: {
          toolbar: toolbarOptions,
          clipboard: {
            matchVisual: false
          }
        }
      });
      this.quill = quill;
      const that = this;
      quill.on('text-change', function (delta, oldDelta, source) {
        if (source == 'user') {
          if (that.onChange) that.onChange(quill.root.innerHTML);
        }
      });
      quill.on('selection-change', function (range) {
        if (!range) {
          const text = quill.getText();
          if (text.length === 1) {
            quill.setContents([{
              insert: 'Title comes here'
            }, {
              insert: '\n'
            }, {
              insert: 'Body comes here'
            }]);
            quill.formatLine(0, 1, 'header', 2); // AFTER HACK
          }

          that.set('active', false);
          if (that.focusIn) that.focusIn();
        } else {
          that.set('active', true);
          if (that.focusOut) that.focusOut();
        }
      });
    },
    willDestroyElement() {
      this._super();
    }
  });
  _exports.default = _default;
});