define("mentorcam-admin/components/topics/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['topics', 'edit'],
    modelName: "topics",
    typeOptions: Ember.computed(function () {
      return [{
        id: 0,
        display: 'Background'
      }, {
        id: 1,
        display: 'Expertise'
      }, {
        id: 2,
        display: 'Industry'
      }];
    }),
    init() {
      this._super();
      this.metadata = {
        topic: this.get('model.id')
      };
    },
    actions: {}
  });
  _exports.default = _default;
});