define("mentorcam-admin/templates/components/users/edit/delete-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DSRSr+gb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inputs form shared panel\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[10,\"strong\"],[12],[2,\"Are you sure?\"],[13],[13],[2,\"\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[12],[1,[35,3,[\"stripe\",\"source_id\"]]],[13],[2,\" will be deleted immediately. This operation is irreversible.\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row actions\"],[12],[2,\"\\n\\n        \"],[8,\"link-to\",[],[[\"@route\",\"@classNames\"],[[34,4],\"shared half button grey medium\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"span\"],[12],[2,\"Cancel\"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n            \"],[10,\"button\"],[14,0,\"shared button error medium disabled\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[14,4,\"button\"],[12],[2,\"\\n                \"],[10,\"span\"],[12],[2,\"Error\"],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n            \"],[11,\"button\"],[16,\"processing\",[34,0]],[24,0,\"shared half button shadow red medium\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[24,4,\"button\"],[4,[38,1],[[32,0],[30,[36,1],[[32,0],\"complete\"],null]],null],[12],[2,\"\\n\\n                \"],[10,\"span\"],[12],[2,\"Delete\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"shared/spinner\",[],[[\"@medium\",\"@white\"],[false,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"processing\",\"action\",\"if\",\"model\",\"return\",\"error\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/users/edit/delete-card.hbs"
    }
  });
  _exports.default = _default;
});