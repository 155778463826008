define("mentorcam-admin/routes/authenticated/conversations/modal/edit/send-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import firebase from 'firebase';
  var _default = Ember.Route.extend({
    firebase: Ember.inject.service(),
    model() {
      const conversation = this.modelFor('authenticated.conversations.modal.edit');
      const model = this.store.createRecord('message', {
        owner: conversation.get('recipient'),
        recipient: conversation.get('owner'),
        conversation: conversation
      });
      return model;
    },
    renderTemplate() {
      this.render({
        into: 'authenticated.conversations.modal'
      });
    }
  });
  _exports.default = _default;
});