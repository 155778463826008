define("mentorcam-admin/controllers/authenticated/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: {
      is_admin: {
        type: 'boolean'
      },
      page: {
        type: "number"
      },
      first_name: {
        type: "string"
      },
      last_name: {
        type: "string"
      },
      email: {
        type: "string"
      }
    }
  });
  _exports.default = _default;
});