define("mentorcam-admin/components/calendar-events/list", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['calendar-events', 'w-2'],
    firebase: Ember.inject.service(),
    async syncAllCalendars() {
      if (this.syncingAllCalendars) return;
      this.set('syncingAllCalendars', true);
      try {
        await this.firebase.functions().httpsCallable('syncAllCalendarEvents')({});
      } catch (error) {
        console.error(error);
      } finally {
        this.set('syncingAllCalendars', false);
      }
    }
  });
  _exports.default = _default;
});