define("mentorcam-admin/helpers/toDate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toDate = toDate;
  function toDate([date]) {
    return date.toDate();
  }
  var _default = Ember.Helper.helper(toDate);
  _exports.default = _default;
});