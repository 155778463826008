define("mentorcam-admin/components/shared/edit/list-manager", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['edit', 'list-manager'],
    store: Ember.inject.service(),
    isDisabled: Ember.computed('namespace', function () {
      if (this.namespace.includes('related_') === true) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      add(position) {
        const id = this.get('selectedModel.id');
        if (!this.get('model.' + this.namespace)) {
          this.set('model.' + this.namespace, Ember.A([]));
        }
        if (this.get('model.' + this.namespace).indexOf(id) === -1) {
          if (position === false) {
            this.get('model.' + this.namespace).pushObject(id);
          } else {
            this.get('model.' + this.namespace).insertAt(0, id);
          }
        }
      },
      moveUp(id) {
        const index = this.get('model.' + this.namespace).indexOf(id);
        this.get('model.' + this.namespace).removeObject(id);
        this.get('model.' + this.namespace).insertAt(index - 1, id);
      },
      moveDown(id) {
        const index = this.get('model.' + this.namespace).indexOf(id);
        this.get('model.' + this.namespace).removeObject(id);
        this.get('model.' + this.namespace).insertAt(index + 1, id);
      },
      delete(id) {
        this.get('model.' + this.namespace).removeObject(id);
      }
    }
  });
  _exports.default = _default;
});