define("mentorcam-admin/transforms/object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Transform.extend({
    deserialize(value) {
      if (typeof value !== 'object') {
        return {};
      } else {
        return value;
      }
    },
    serialize(value) {
      if (typeof value !== 'object') {
        return {};
      } else {
        return value;
      }
    }
  });
  _exports.default = _default;
});