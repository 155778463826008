define("mentorcam-admin/initializers/editor", ["exports", "mentorcam-admin/services/editor"], function (_exports, _editor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    app.register('service:editor', _editor.default);
    app.inject('route', 'editor', 'service:editor');
    app.inject('controller', 'editor', 'service:editor');
    app.inject('component', 'editor', 'service:editor');
  }
  var _default = {
    name: 'editor',
    initialize: initialize
  };
  _exports.default = _default;
});