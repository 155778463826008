define("mentorcam-admin/helpers/word-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.wordCount = wordCount;
  function wordCount([input]) {
    if (!input) {
      input = "";
    }
    input = input.replace(/\s\s+/g, ' ');
    return input.split(" ").length;
  }
  var _default = Ember.Helper.helper(wordCount);
  _exports.default = _default;
});