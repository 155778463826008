define("mentorcam-admin/components/report/range", ["exports", "mentorcam-admin/components/report/edit", "moment"], function (_exports, _edit, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['traction', 'range'],
    init() {
      this._super();
      this.set('from', (0, _moment.default)().subtract(1, 'days').format("YYYY-MM-DD"));
      this.set('to', (0, _moment.default)().format("YYYY-MM-DD"));
    },
    identifier: Ember.computed('from', 'to', function () {
      return String(this.from + "/" + this.to);
    }),
    actions: {
      export() {
        const array = [this.exportConversationsUrl, this.exportSessionLogsUrl, this.exportSearchLogsUrl, this.exportCheckoutLogsUrl, this.exportMessagesUrl, this.exportMessageDraftsUrl, this.exportChargesUrl, this.exportUsersUrl, this.exportGiftCardsUrl];
        array.forEach(item => {
          window.open(item, '_blank').focus();
        });
      }
    }
  });
  _exports.default = _default;
});