define("mentorcam-admin/components/users/edit/auth", ["exports", "mentorcam-admin/components/shared/action", "ember-get-config"], function (_exports, _action, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['users', 'auth', 'column'],
    firebase: Ember.inject.service(),
    token: "loading",
    async didInsertElement() {
      try {
        const {
          data
        } = await this.firebase.functions().httpsCallable('getUserToken')({
          'user': this.get('model.id')
        });
        if (!data.status) {
          if (this.isDestroyed !== true) this.set('token', 'error');
        } else {
          if (this.isDestroyed !== true) this.set('token', data.token);
        }
      } catch (error) {
        if (this.isDestroyed !== true) this.set('token', 'error');
      }
    },
    webLink: Ember.computed('token', function () {
      if (this.token) {
        return _emberGetConfig.default.domainUriPrefix + "/login?token=" + this.token;
      } else {
        return null;
      }
    }),
    deferredDeepLink: Ember.computed('token', function () {
      if (this.token) {
        return 'mentorcam://deep_link_value=token:' + this.token;
      } else {
        return null;
      }
    }),
    appsflyerDeepLink: Ember.computed('token', function () {
      if (this.token) {
        return 'https://appsflyer.mentor.cam/du6n?deep_link_value=token:' + this.token;
      } else {
        return null;
      }
    })
  });
  _exports.default = _default;
});