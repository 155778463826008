define("mentorcam-admin/models/payout", ["exports", "mentorcam-ember-shared/models/payout"], function (_exports, _payout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _payout.default.extend({
    bankType: Ember.computed('bank.type', function () {
      if (this.get('bank.type') === "us") {
        return "U.S bank transfer";
      } else if (this.get('bank.type') === "international") {
        return "International bank transfer";
      } else {
        return "Unknown";
      }
    }),
    accountNumber: Ember.computed('bank.type', function () {
      if (this.get('bank.type') === "us") {
        return this.get('bank.number');
      } else if (this.get('bank.type') === "international") {
        return this.get('bank.iban');
      } else {
        return "Unknown";
      }
    })
  });
  _exports.default = _default;
});