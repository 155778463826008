define("mentorcam-admin/templates/authenticated/filters/modal/edit/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3oDb6m/o",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/edit/filters\",[],[[\"@list\",\"@namespace\",\"@model\",\"@return\",\"@destination\"],[[34,0,[\"filters\"]],\"filters\",[34,0],\"authenticated.filters.modal.edit\",\"authenticated.filters.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/filters/modal/edit/filters.hbs"
    }
  });
  _exports.default = _default;
});