define("mentorcam-admin/components/shared/edit/user-profiles", ["exports", "mentorcam-admin/components/shared/edit/list-manager", "mentorcam-admin/mixins/public-user-profiles"], function (_exports, _listManager, _publicUserProfiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _listManager.default.extend(_publicUserProfiles.default, {
    models: Ember.computed.reads('publicUserProfiles'),
    actions: {
      async process() {
        const list = [];
        this.user_profiles.split('"').join('').split('\n').forEach(element => {
          let array1 = element.split(' ');
          array1 = [array1[array1.length - 1]];
          const profile = this.publicUserProfiles.find(userProfile => {
            const array2 = userProfile.get('name').split(' ').reverse();
            const filteredArray = array1.filter(value => array2.includes(value));
            return filteredArray.length > 0;
          });
          if (profile) {
            if (list.indexOf(profile) === -1) {
              list.push(profile.id);
            }
          }
        });
        if (list.length) {
          this.set('list', list);
        }
      },
      deduplicate() {
        const list = this.list;
        const uniqueList = list.filter((item, index) => {
          return list.indexOf(item) === index;
        });
        this.set('list', uniqueList);
      },
      shuffle() {
        function shuffleArray(array) {
          let currentIndex = array.length,
            randomIndex;

          // While there remain elements to shuffle.
          while (currentIndex != 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
          }
          return array;
        }
        const list = shuffleArray(this.list);
        this.set('list', list);
      }
    }
  });
  _exports.default = _default;
});