define("mentorcam-admin/models/payment-link", ["exports", "mentorcam-ember-shared/models/payment-link", "ember-get-config", "moment"], function (_exports, _paymentLink, _emberGetConfig, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _paymentLink.default.extend({
    expiryDate: Ember.computed('created_at', 'validity', function () {
      const created_at = this.created_at ? this.created_at : new Date();
      return (0, _moment.default)(created_at).add({
        days: this.validity
      }).toDate();
    }),
    displayBullets: Ember.computed('bullets', function () {
      if (this.bullets) {
        return this.bullets.split("\n");
      } else {
        return [];
      }
    }),
    link: Ember.computed('id', function () {
      return _emberGetConfig.default.domainUriPrefix + "/payment-links/" + this.id;
    })
  });
  _exports.default = _default;
});