define("mentorcam-admin/components/shared/edit", ["exports", "mentorcam-admin/components/shared/section", "mentorcam-admin/mixins/timezones", "mentorcam-admin/mixins/development", "mentorcam-admin/mixins/call-options", "mentorcam-admin/mixins/admin-roles"], function (_exports, _section, _timezones, _development, _callOptions, _adminRoles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend(_adminRoles.default, _callOptions.default, _development.default, {
    classNames: ['edit'],
    classNameBindings: ['column'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    column: true,
    embedded: false,
    passModel: false,
    //

    timezoneOptions: Ember.computed(function () {
      return (0, _timezones.timezones)();
    }),
    //

    deprecatedTimezones: Ember.computed(function () {
      return (0, _timezones.deprecatedTimezones)();
    }),
    socialOptions: Ember.computed(function () {
      return [{
        id: 'facebook',
        display: "Facebook"
      }, {
        id: 'twitter',
        display: "Twitter"
      }, {
        id: 'linkedin',
        display: "LinkedIn"
      }, {
        id: 'instagram',
        display: "Instagram"
      }, {
        id: 'tiktok',
        display: "TikTok"
      }, {
        id: 'snapchat',
        display: "Snapchat"
      }, {
        id: 'twitch',
        display: "Twitch"
      }, {
        id: 'youtube',
        display: "YouTube"
      }];
    }),
    platformOptions: Ember.computed(function () {
      return [{
        id: 'ios',
        display: "iOs"
      }, {
        id: 'android',
        display: "Android"
      }, {
        id: 'web',
        display: "Web"
      }];
    }),
    paymentMethodOptions: Ember.computed(function () {
      return [{
        id: 'card',
        display: "Card"
      }, {
        id: 'apple',
        display: "Apple"
      }, {
        id: 'google',
        display: "Google"
      }];
    }),
    paymentTypeOptions: Ember.computed(function () {
      return [{
        id: 'stripe',
        display: "Stripe"
      }, {
        id: 'apple',
        display: "Apple"
      }, {
        id: 'credit',
        display: "Credit"
      }, {
        id: 'gift-card',
        display: "Gift Card"
      }];
    }),
    authenticationOptions: Ember.computed(function () {
      return [{
        id: 'email',
        display: "Email"
      }, {
        id: 'apple',
        display: "Apple"
      }, {
        id: 'google',
        display: "Google"
      }];
    }),
    //

    topics: Ember.computed('store.countries.model', function () {
      return this.store.peekAll('topic');
    }),
    publicTopics: Ember.computed.filter("topics", function (item) {
      return item.get('is_deleted') !== true;
    }),
    countries: Ember.computed('store.countries.model', function () {
      return this.store.findAll('country');
    }),
    categories: Ember.computed('store.categories.model', function () {
      return this.store.findAll('category');
    }),
    filters: Ember.computed('store.filters.model', function () {
      return this.store.findAll('filter');
    }),
    groups: Ember.computed('store.groups.model', function () {
      return this.store.findAll('group');
    }),
    //

    delete: Ember.computed('destination', function () {
      return this.destination + ".modal.edit.delete";
    }),
    //

    async deleteAlgolia(collection) {
      if (this.deletingAlgolia) return;
      this.set('deletingAlgolia', true);
      try {
        const result = await this.firebase.functions().httpsCallable('deleteAlgolia')({
          'document': this.get('model.id'),
          collection: collection
        });
        console.log(result.status);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('deletingAlgolia', false);
      }
    },
    //

    actions: {
      async complete() {
        if (this.get('processing')) return;
        this.set('processing', true);
        try {
          if (this.transform) {
            this.transform(this.get('model'));
          }
          if (this.operation) {
            this.operation();
          }
          await this.get('model').save();
        } catch (error) {
          this.set('error', error);
          console.error(error);
        }
        if (this.finalise) {
          this.finalise();
        }
        this.set('processing', false);
        if (!this.get('error')) {
          if (this.passModel) {
            this.get('router').transitionTo(this.destination, this.get('model'));
          } else {
            this.get('router').transitionTo(this.destination);
          }
        }
      },
      focusIn() {
        this.set('error', null);
      },
      close() {
        if (this.return) {
          if (this.processing !== true) {
            this.router.transitionTo(this.return);
          }
        }
      }
    }
  });
  _exports.default = _default;
});