define("mentorcam-admin/templates/authenticated/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1bi9O42J",
    "block": "{\"symbols\":[],\"statements\":[[8,\"countries/list\",[],[[\"@page\",\"@model\"],[[32,0,[\"page\"]],[34,0]]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/countries.hbs"
    }
  });
  _exports.default = _default;
});