define("mentorcam-admin/components/shared/complete", ["exports", "mentorcam-admin/components/shared/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend({
    classNames: ['edit', 'complete']
  });
  _exports.default = _default;
});