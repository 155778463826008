define("mentorcam-admin/routes/authenticated/shared/add", ["exports", "mentorcam-admin/routes/authenticated/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    model() {
      return this.store.createRecord(this.get('modelName'));
    }
  });
  _exports.default = _default;
});