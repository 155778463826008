define("mentorcam-admin/components/payment-links/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/identifier-options"], function (_exports, _edit, _identifierOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_identifierOptions.default, {
    classNames: ['payment-links', 'edit'],
    modelName: 'payment-link',
    firebase: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement() {
      this._super();
      if (this.get('model.isNew')) {
        const id = this.get('session.data.authenticated.user.uid');
        const userProfile = this.store.peekRecord('user-profile', id);
        this.set('model.owner', userProfile);
      }
    },
    programOptions: Ember.computed(function () {
      return [{
        id: 'accelerator',
        display: 'Accelerator Prep'
      }, {
        id: 'fundraising',
        display: 'Fundraising Starter Pack'
      }, {
        id: 'mvp',
        display: 'From MVP to PMF'
      }];
    }),
    typeOptions: Ember.computed(function () {
      return [{
        id: 'subscription',
        display: 'Membership'
      }, {
        id: 'program',
        display: 'Program'
      }, {
        id: 'package',
        display: 'Package'
      }, {
        id: 'custom-package',
        display: 'Custom Package'
      }];
    }),
    packageCallsOptions: Ember.computed(function () {
      return [{
        id: 3,
        display: '3'
      }, {
        id: 5,
        display: '5'
      }, {
        id: 8,
        display: '8'
      }];
    }),
    callsOptions: Ember.computed('model.identifier', function () {
      return [{
        id: 1,
        display: '1'
      }, {
        id: 2,
        display: '2'
      }, {
        id: 3,
        display: '3'
      }, {
        id: 4,
        display: '4'
      }, {
        id: 5,
        display: '5'
      }, {
        id: 6,
        display: '6'
      }, {
        id: 7,
        display: '7'
      }, {
        id: 8,
        display: '8'
      }, {
        id: 9,
        display: '9'
      }, {
        id: 10,
        display: '10'
      }];
    }),
    validityOptions: Ember.computed(function () {
      const array = [{
        id: 1,
        display: '1 day'
      }, {
        id: 2,
        display: '2 days'
      }, {
        id: 3,
        display: '3 days'
      }, {
        id: 4,
        display: '4 days'
      }, {
        id: 5,
        display: '5 days'
      }, {
        id: 6,
        display: '6 days'
      }, {
        id: 7,
        display: '7 days'
      }, {
        id: 8,
        display: '8 days'
      }, {
        id: 9,
        display: '9 days'
      }, {
        id: 10,
        display: '10 days'
      }, {
        id: 11,
        display: '11 days'
      }, {
        id: 12,
        display: '12 days'
      }, {
        id: 13,
        display: '13 days'
      }, {
        id: 14,
        display: '14 days'
      }];
      return array;
    }),
    discountOptions: Ember.computed(function () {
      const array = [{
        id: 0,
        display: '$0'
      }, {
        id: 50,
        display: '$50'
      }, {
        id: 100,
        display: '$100'
      }, {
        id: 150,
        display: '$150'
      }, {
        id: 200,
        display: '$200'
      }, {
        id: 250,
        display: '$250'
      }, {
        id: 300,
        display: '$300'
      }, {
        id: 400,
        display: '$400'
      }, {
        id: 490,
        display: '$490'
      }];
      return array;
    })
  });
  _exports.default = _default;
});