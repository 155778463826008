define("mentorcam-admin/helpers/firestore", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.firestore = firestore;
  function firestore([collection, id]) {
    return "https://console.firebase.google.com/u/1/project/" + _emberGetConfig.default.firebase.projectId + "/firestore/data/~2F" + collection + "~2F" + id;
  }
  var _default = Ember.Helper.helper(firestore);
  _exports.default = _default;
});