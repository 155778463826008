define("mentorcam-admin/templates/components/shared/content/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TNao73Dx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/content/rich-text-editor\",[],[[\"@value\",\"@onChange\"],[[34,3],[30,[36,1],[[32,0],\"onChange\"],null]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@classNames\"],[[34,4],\"shared half button grey large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[12],[2,\"Cancel\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[10,\"button\"],[14,0,\"shared button error large disabled\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[14,4,\"button\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"Error\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n        \"],[11,\"button\"],[16,\"processing\",[34,0]],[24,0,\"shared half button shadow black large\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"save\"],null],[12],[2,\"\\n\\n            \"],[10,\"span\"],[12],[2,\"Save\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"shared/spinner\",[],[[\"@medium\",\"@white\"],[false,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"processing\",\"action\",\"if\",\"value\",\"return\",\"error\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/content/editor.hbs"
    }
  });
  _exports.default = _default;
});