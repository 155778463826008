define("mentorcam-admin/templates/authenticated/user-profiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DfkWuHtp",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,1],\"content\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"user-profiles/content\",[],[[\"@page\",\"@model\",\"@is_approved\",\"@is_public\"],[[32,0,[\"page\"]],[34,0],[32,0,[\"is_approved\"]],[34,4]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],\"finance\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"user-profiles/finance\",[],[[\"@page\",\"@model\",\"@is_approved\",\"@is_public\"],[[32,0,[\"page\"]],[34,0],[32,0,[\"is_approved\"]],[34,4]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],\"traction\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"user-profiles/traction\",[],[[\"@page\",\"@model\",\"@is_approved\",\"@is_public\"],[[32,0,[\"page\"]],[34,0],[32,0,[\"is_approved\"]],[34,4]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],\"onboarding\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[8,\"user-profiles/onboarding\",[],[[\"@page\",\"@model\"],[[32,0,[\"page\"]],[34,0]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n    \"],[8,\"user-profiles/list\",[],[[\"@page\",\"@model\"],[[32,0,[\"page\"]],[34,0]]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"view\",\"eq\",\"if\",\"is_public\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/user-profiles.hbs"
    }
  });
  _exports.default = _default;
});