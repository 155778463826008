define("mentorcam-admin/routes/authenticated/conversations/modal/edit/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render({
        into: 'authenticated.conversations.modal'
      });
    },
    async model() {
      const id = this.modelFor('authenticated.conversations.modal.edit').get('id');
      const list = await this.store.query('message', {
        backgroundReload: false,
        reload: true,
        queryId: true,
        filter(reference) {
          return reference.where('conversation', "==", id);
        }
      });
      return list;
    }
  });
  _exports.default = _default;
});