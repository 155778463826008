define("mentorcam-admin/components/user-profiles/edit/google-calendar", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['user-profiles', 'column', 'google-calendar'],
    store: Ember.inject.service(),
    firebase: Ember.inject.service(),
    async listGoogleCalendarEvents() {
      const {
        data
      } = await this.firebase.functions().httpsCallable('listGoogleCalendarEvents')({
        'user': this.get('model.id')
      });
      if (this.isDestroyed === false) {
        this.set('data', data);
      }
    },
    async didInsertElement() {
      await this.listGoogleCalendarEvents();
    }
  });
  _exports.default = _default;
});