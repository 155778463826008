define("mentorcam-admin/controllers/authenticated/user-profiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: {
      is_public: {
        type: 'boolean'
      },
      is_deleted: {
        type: "boolean"
      },
      page: {
        type: "number"
      },
      is_approved: {
        type: "boolean"
      },
      view: {
        type: "string"
      }
    }
  });
  _exports.default = _default;
});