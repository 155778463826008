define("mentorcam-admin/templates/authenticated/gift-cards/modal/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3K2fHhKj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"gift-cards/edit\",[],[[\"@embedded\",\"@model\",\"@destination\"],[true,[34,0],\"authenticated.gift-cards\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/gift-cards/modal/add.hbs"
    }
  });
  _exports.default = _default;
});