define("mentorcam-admin/templates/components/shared/complete/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7ukQW99w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"100px\"],[14,\"height\",\"100px\"],[14,\"viewBox\",\"0 0 100 100\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n    \"],[10,\"g\"],[14,1,\"Page-1\"],[14,\"stroke\",\"none\"],[14,\"stroke-width\",\"1\"],[14,\"fill\",\"none\"],[14,\"fill-rule\",\"evenodd\"],[12],[2,\"\\n        \"],[10,\"circle\"],[14,\"fill\",\"#F8C85E\"],[14,\"cx\",\"50\"],[14,\"cy\",\"50\"],[14,\"r\",\"50\"],[12],[13],[2,\"\\n        \"],[10,\"g\"],[14,\"transform\",\"translate(50.000000, 46.500000) rotate(30.000000) translate(-50.000000, -46.500000) translate(38.000000, 26.000000)\"],[14,\"fill\",\"#000000\"],[12],[2,\"\\n            \"],[10,\"rect\"],[14,\"x\",\"19.7983107\"],[14,\"y\",\"0.703512971\"],[14,\"width\",\"4\"],[14,\"height\",\"40\"],[14,\"rx\",\"2\"],[12],[13],[2,\"\\n            \"],[10,\"rect\"],[14,\"x\",\"0.30618584\"],[14,\"y\",\"36.6870454\"],[14,\"width\",\"23\"],[14,\"height\",\"4\"],[14,\"rx\",\"2\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/complete/icon.hbs"
    }
  });
  _exports.default = _default;
});