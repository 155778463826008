define("mentorcam-admin/templates/components/shared/recipient-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "U7Mc8Qe3",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,0,[\"calendar\",\"complete\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"circle complete\"],[12],[1,[35,0,[\"activity\",\"calendar_events\",\"calendar\",\"total\"]]],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"circle\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"if\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/recipient-badges.hbs"
    }
  });
  _exports.default = _default;
});