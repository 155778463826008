define("mentorcam-admin/helpers/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.number = number;
  function number([input]) {
    if (!input) {
      input = 0;
    }
    return input.toLocaleString();
  }
  var _default = Ember.Helper.helper(number);
  _exports.default = _default;
});