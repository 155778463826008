define("mentorcam-admin/helpers/traction-elapsed-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.tractionElapsedTime = tractionElapsedTime;
  function tractionElapsedTime([milliseconds]) {
    const day = milliseconds / (1000 * 60 * 60 * 24);
    const hour = milliseconds / (1000 * 60 * 60);
    const minute = milliseconds / (1000 * 60);
    if (day < 1) {
      if (hour < 1) {
        return minute.toFixed(2) + " minutes";
      } else {
        return hour.toFixed(2) + " hours";
      }
    } else {
      return day.toFixed(2) + " days";
    }
  }
  var _default = Ember.Helper.helper(tractionElapsedTime);
  _exports.default = _default;
});