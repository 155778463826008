define("mentorcam-admin/components/conversations/edit/create-charge", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/duration-options"], function (_exports, _action, _durationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_durationOptions.default, {
    classNames: ['conversations', 'add'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    quantity: 30,
    amount: 0,
    async operation() {
      return this.firebase.functions().httpsCallable('createCreditCharge')({
        conversation: this.model.id,
        product_type: this.product_type,
        amount: this.amount,
        quantity: this.product_type === "live-call" ? this.quantity : 1
      });
    },
    async didInsertElement() {
      this._super();
      const pricingOptions = [];
      const recipient = await this.get('model.recipient');
      const pricing = recipient.get('pricing');
      if (pricing) {
        if (pricing.live_call && pricing.live_call.enabled === true) {
          pricingOptions.push({
            display: "Video call " + "($" + Math.round(pricing.live_call.item_price) + ")",
            id: "live-call"
          });
          if (this.isDestroyed !== true) {
            this.set('amount', Math.round(pricing.live_call.item_price));
          }
        }
        if (pricing.video_message && pricing.video_message.enabled === true) {
          pricingOptions.push({
            display: "Message" + " ($" + Math.round(pricing.video_message.item_price) + ")",
            id: "video-message"
          });
        }
        if (this.isDestroyed !== true) {
          this.set('pricingOptions', pricingOptions);
        }
      }
    },
    navigate() {
      this.router.transitionTo("authenticated.conversations.modal.edit", this.model.id);
    }
  });
  _exports.default = _default;
});