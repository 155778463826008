define("mentorcam-admin/templates/components/shared/profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bexjTbX/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,0,[\"trending_image\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[8,\"shared/image\",[],[[\"@model\",\"@width\",\"@height\",\"@face\"],[[34,0,[\"trending_image\"]],320,320,true]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"monogramm\"],[12],[2,\"\\n            \"],[10,\"p\"],[12],[1,[35,0,[\"monogramm\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"userProfile\",\"if\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/profile-picture.hbs"
    }
  });
  _exports.default = _default;
});