define("mentorcam-admin/models/user-profile", ["exports", "mentorcam-ember-shared/models/user-profile"], function (_exports, _userProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _userProfile.default.extend({
    changeTracker: {
      only: ['name']
    },
    store: Ember.inject.service(),
    displayBullets: Ember.computed('bullets', function () {
      if (this.bullets) {
        return this.bullets.split("\n");
      } else {
        return false;
      }
    }),
    calculatedTrendingIndex: Ember.computed('is_listed', 'trending_index', function () {
      if (this.is_listed !== true) {
        return 1000 + this.trending_index;
      } else {
        return this.trending_index;
      }
    }),
    relatedTopics: Ember.computed('id', function () {
      const topics = this.store.peekAll('topic');
      return topics.filter(item => {
        return item.get('user_profiles').indexOf(this.id) !== -1;
      });
    }),
    user: Ember.computed('id', function () {
      return this.store.findRecord('user', this.id);
    }),
    monogramm: Ember.computed('name', function () {
      if (this.name) {
        const names = this.name.split(' ');
        if (names.length == 2) {
          return names[0][0] + names[1][0];
        } else {
          return names[0][0] + names[0][1];
        }
      } else {
        return null;
      }
    }),
    display: Ember.computed.reads('name'),
    pricingOptions: Ember.computed('pricing', function () {
      const array = [];
      const pricing = this.pricing;
      if (pricing.live_call && pricing.live_call.enabled === true) {
        array.push({
          display: "Video call " + "($" + pricing.live_call.item_price + ")",
          id: "live-call"
        });
      }
      if (pricing.video_message && pricing.video_message.enabled === true) {
        array.push({
          display: "Video Response" + "($" + pricing.video_message.item_price + ")",
          id: "video-message"
        });
      }
      if (pricing.text_message && pricing.text_message.enabled === true) {
        array.push({
          display: "Text Response" + "($" + pricing.text_message.item_price + ")",
          id: "text-message"
        });
      }
      return array;
    }),
    promoCodes: Ember.computed('store', function () {
      return this.store.findAll('promo-code');
    }),
    relevantPromoCodes: Ember.computed.filter('promoCodes', ['length'], function (item) {
      return this.id === item.get('owner.id');
    })
  });
  _exports.default = _default;
});