define("mentorcam-admin/components/shared/delete", ["exports", "mentorcam-admin/components/shared/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend({
    classNames: ['shared', 'delete'],
    router: Ember.inject.service(),
    actions: {
      async delete() {
        if (this.deleting) return;
        this.set('deleting', true);
        try {
          await this.model.destroyRecord();
        } catch (error) {
          console.error(error);
          this.set('error', error);
        }
        this.set('deleting', false);
        if (!this.error) {
          this.router.transitionTo(this.destination);
        }
      },
      close() {
        if (this.return) {
          if (this.deleting !== true) {
            this.router.transitionTo(this.return);
          }
        }
      }
    }
  });
  _exports.default = _default;
});