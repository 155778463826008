define("mentorcam-admin/routes/authenticated/shared/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord(this.get('modelName'), params.id, {
        include: this.get('include')
      });
    }
  });
  _exports.default = _default;
});