define("mentorcam-admin/components/messages/edit/assets", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['messages', 'assets', 'column'],
    firebase: Ember.inject.service(),
    async getMediaAssets() {
      const {
        data
      } = await this.firebase.functions().httpsCallable('getMessageMediaAssets')({
        'message': this.get('model.id')
      });
      if (!data.status) {
        this.set('error', true);
      } else {
        this.set('assets', data);
      }
    },
    async didInsertElement() {
      await Promise.all([this.getMediaAssets()]);
    }
  });
  _exports.default = _default;
});