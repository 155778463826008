define("mentorcam-admin/components/calls/user-profiles", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['calls', 'user-profiles', 'w-3'],
    userProfiles: Ember.computed('model', function () {
      const view = this.view;
      const map = {};
      this.get('model').forEach(element => {
        const id = element.get(view + '.id');
        if (map[id]) {
          map[id].push(element);
        } else {
          map[id] = [element];
        }
      });
      const array = [];
      Object.keys(map).forEach(function (key) {
        const list = map[key];
        array.push({
          model: list[0].get(view),
          list: list.sort(function (b, a) {
            return a.get('calculatedTimestamp') - b.get('calculatedTimestamp');
          })
        });
      });
      return array;
    })
  });
  _exports.default = _default;
});