define("mentorcam-admin/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      if (this.get('session.isAuthenticated') === true) {
        transition.abort();
        this.transitionTo('authenticated');
      }
    }
  });
  _exports.default = _default;
});