define("mentorcam-admin/routes/authenticated/subscriptions", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    queryParams: {
      is_cancelled: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      recipient: {
        refreshModel: true
      },
      owner: {
        refreshModel: true
      }
    },
    model(params) {
      if (params.recipient) {
        params.user_profiles = params.recipient;
        delete params.recipient;
      }
      return this.store.query('subscription', this.transcodeParams(params, true));
    }
  });
  _exports.default = _default;
});