define("mentorcam-admin/components/users/edit/create-conversation", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/public-user-profiles"], function (_exports, _action, _publicUserProfiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_publicUserProfiles.default, {
    classNames: ['conversations', 'add'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    session_count: 30,
    is_listed: true,
    async operation() {
      const recipient = this.recipient;
      if (recipient) {
        const input = {
          'user': this.get('model.id'),
          recipient: recipient.id,
          is_listed: this.is_listed
        };
        if (this.is_listed) {
          input.session_count = this.session_count;
        }
        return this.firebase.functions().httpsCallable('triggerConversation')(input);
      } else {
        return null;
      }
    },
    navigate() {
      this.router.transitionTo("authenticated.conversations.modal.edit", this.get('data.conversation'), {
        queryParams: {
          owner: this.get('model.id')
        }
      });
    }
  });
  _exports.default = _default;
});