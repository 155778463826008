define("mentorcam-admin/components/shared/modal", ["exports", "mentorcam-admin/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_modal.default, {
    classNames: ['shared', 'modal'],
    router: Ember.inject.service(),
    click(event) {
      const isModal = event.target.classList.contains('modal');
      if (isModal) {
        this.get('router').transitionTo(this.return);
      }
    }
  });
  _exports.default = _default;
});