define("mentorcam-admin/templates/components/inputs/svg-input-firebase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PaN8ep66",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"lead\"],[12],[2,\"\\n\\n\"],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"h4\"],[12],[10,\"span\"],[12],[1,[34,2]],[13],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\" \"],[10,\"span\"],[14,0,\"star\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"inputs/file-droplet\",[],[[\"@file\",\"@showPreview\",\"@accept\",\"@added\"],[[34,4],false,[34,5],[30,[36,6],[[32,0],\"added\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[10,\"button\"],[15,\"processing\",[34,7]],[14,0,\"shared button shadow black small\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[14,4,\"button\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"Upload\"],[13],[2,\"\\n\"],[6,[37,1],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"shared/spinner\",[],[[\"@medium\",\"@white\"],[false,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,0,[\"id\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"preview\"],[12],[2,\"\\n\\n\"],[6,[37,1],[[35,0,[\"cloudinary\",\"metadata\",\"secure_url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"img\"],[15,\"src\",[34,0,[\"cloudinary\",\"metadata\",\"secure_url\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"if\",\"displayPlaceholder\",\"required\",\"file\",\"accept\",\"action\",\"processing\",\"unless\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/inputs/svg-input-firebase.hbs"
    }
  });
  _exports.default = _default;
});