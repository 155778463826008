define("mentorcam-admin/templates/components/shared/csv-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hpBSsPiE",
    "block": "{\"symbols\":[\"preview\"],\"statements\":[[10,\"div\"],[14,0,\"lead\"],[12],[2,\"\\n\\n    \"],[10,\"h4\"],[12],[10,\"span\"],[12],[1,[34,0]],[13],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\" \"],[10,\"span\"],[14,0,\"star\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[13],[2,\"\\n\\n    \"],[8,\"inputs/file-droplet\",[],[[\"@autosave\",\"@showPreview\",\"@file\",\"@accept\",\"@added\"],[[34,3],false,[34,4],[34,5],[30,[36,6],[[32,0],\"csvAdded\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[10,\"button\"],[15,\"processing\",[34,7]],[14,0,\"shared button shadow black medium\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[14,4,\"button\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"Select\"],[13],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"shared/spinner\",[],[[\"@medium\",\"@black\"],[false,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"required\",\"if\",\"autosave\",\"file\",\"accept\",\"action\",\"processing\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/csv-input.hbs"
    }
  });
  _exports.default = _default;
});