define("mentorcam-admin/initializers/vh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    function setup() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    if (window && window.addEventListener) {
      window.addEventListener('resize', () => {
        setup();
      });
      setup();
    }
  }
  var _default = {
    name: 'vh',
    initialize: initialize
  };
  _exports.default = _default;
});