define("mentorcam-admin/components/shared/profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['profile-picture'],
    classNameBindings: ["rectangular", "small"]
  });
  _exports.default = _default;
});