define("mentorcam-admin/models/message", ["exports", "mentorcam-ember-shared/models/message", "mentorcam-admin/mixins/device-display"], function (_exports, _message, _deviceDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _message.default.extend(_deviceDisplay.default, {
    size: Ember.computed('video.cloudinary.metadata.bytes', function () {
      if (this.video?.cloudinary?.metadata?.bytes) {
        return (this.video?.cloudinary?.metadata?.bytes / (1024 * 1024)).toFixed(2);
      } else {
        return null;
      }
    }),
    truncatedText: Ember.computed('text', 'text.length', function () {
      if (this.text.length > 140) {
        return this.text.slice(0, 140) + "...";
      } else {
        return this.text;
      }
    }),
    duration: Ember.computed('video.cloudinary.metadata.duration', function () {
      if (this.video?.cloudinary?.metadata?.duration) {
        return Math.round(this.video?.cloudinary?.metadata?.duration);
      } else {
        return null;
      }
    }),
    time: Ember.computed('video.{compress_time,process_time,upload_time}', function () {
      return (Math.round(this.video?.upload_time) || 0) + (Math.round(this.video?.process_time) || 0) + (Math.round(this.video?.compress_time) || 0);
    }),
    createdOn: Ember.computed('video.created_on', function () {
      return Math.round(this.video?.created_on) || "";
    }),
    isContentTypeText: Ember.computed.equal('content_type', "text"),
    isContentTypeVideo: Ember.computed('content_type', function () {
      return this.content_type === "video" || !this.content_type;
    }),
    displayType: Ember.computed('count', 'is_owner', 'triggered', function () {
      if (this.triggered) {
        return "Bot";
      } else if (this.is_owner) {
        return "Question" + " (" + this.count + ")";
      } else {
        return "Answer" + " (" + this.count + ")";
      }
    })
  });
  _exports.default = _default;
});