define("mentorcam-admin/helpers/gift-card-redemption", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.giftCardRedemption = giftCardRedemption;
  function giftCardRedemption([short_id, username, product_type, quantity]) {
    try {
      let domain = _emberGetConfig.default.domainUriPrefix;
      if (window.location.href.indexOf('localhost') !== -1) {
        domain = "http://localhost:3000";
      }
      const formulas = {
        "live-call": "{{username}}/video-call/schedule?quantity={{quantity}}&gift={{short_id}}",
        "video-message": "{{username}}/message/text?gift={{short_id}}"
      };
      return domain + "/" + formulas[product_type].replace("{{short_id}}", short_id).replace("{{quantity}}", quantity).replace("{{username}}", username);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  var _default = Ember.Helper.helper(giftCardRedemption);
  _exports.default = _default;
});