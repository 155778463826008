define("mentorcam-admin/helpers/linebreak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linebreak = linebreak;
  function linebreak([input]) {
    if (!input) {
      return null;
    }
    return input.split("\n").join("<br>");
  }
  var _default = Ember.Helper.helper(linebreak);
  _exports.default = _default;
});