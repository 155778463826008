define("mentorcam-admin/components/users/edit/password", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['users', 'username'],
    firebase: Ember.inject.service(),
    async operation() {
      return this.firebase.functions().httpsCallable('changePassword')({
        user: this.get('model.id'),
        password: this.get('password')
      });
    },
    criteria(value) {
      if (value) {
        if (value.length > 7) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    navigate() {
      this.get('router').transitionTo("authenticated.users.modal.edit");
    }
  });
  _exports.default = _default;
});