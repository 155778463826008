define("mentorcam-admin/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mobile: '(max-width: 768px)',
    tablet: '(max-width: 1280px)',
    desktop: '(min-width: 1281px)'
  };
  _exports.default = _default;
});