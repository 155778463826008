define("mentorcam-admin/helpers/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.status = status;
  function status([value, yes, no]) {
    if (value) {
      return yes;
    } else {
      return no;
    }
  }
  var _default = Ember.Helper.helper(status);
  _exports.default = _default;
});