define("mentorcam-admin/components/content-articles/edit/content", ["exports", "mentorcam-admin/components/shared/content/content"], function (_exports, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _content.default.extend({
    classNames: ['content-articles', 'content'],
    init() {
      this._super();
      this.html = this.get('model.content');
      this.initial = this.html;
    },
    actions: {
      async save() {
        if (this.processing) {
          return;
        }
        this.set('processing', true);
        try {
          this.model.set('content', this.html);
          await this.model.save();
          this.router.transitionTo(this.return);
        } catch (error) {
          this.set('error', error);
          console.error(error);
        } finally {
          this.set('processing', false);
        }
      },
      focusIn() {
        this.set('active', true);
      },
      focusOut() {
        this.set('active', false);
      },
      onChange(html) {
        if (this.initial !== html) {
          this.set('shouldSave', true);
        } else {
          this.set('shouldSave', false);
        }

        //

        this.set('html', html);
      }
    }
  });
  _exports.default = _default;
});