define("mentorcam-admin/components/user-profiles/content", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['user-profiles', 'w-3'],
    firebase: Ember.inject.service(),
    is_listed: true,
    actions: {
      switchListed() {
        this.set('is_listed', !this.is_listed);
      }
    },
    filterModels: Ember.computed('model', 'model.@each.calculatedTrendingIndex', 'is_listed', function () {
      const is_listed = this.is_listed;
      return this.model.filter(function (item) {
        return item.get('is_listed') === is_listed;
      });
    }),
    async syncAlgolia() {
      if (this.syncingAlgolia) return;
      this.set('syncingAlgolia', true);
      try {
        await this.firebase.functions().httpsCallable('syncAlgolia')({});

        //this.set('mediaAssets', result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('syncingAlgolia', false);
      }
    },
    async alignUserProfiles() {
      if (this.aligningUserProfiles) return;
      this.set('aligningUserProfiles', true);
      try {
        await this.firebase.functions().httpsCallable('alignUserProfiles')({});

        //this.set('mediaAssets', result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('aligningUserProfiles', false);
      }
    }
  });
  _exports.default = _default;
});