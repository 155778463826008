define("mentorcam-admin/components/shared/edit/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['shared', 'edit-section', 'row'],
    classNameBindings: ['open'],
    actions: {
      open() {
        this.toggleProperty('open');
      }
    }
  });
  _exports.default = _default;
});