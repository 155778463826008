define("mentorcam-admin/helpers/display-date", ["exports", "mentorcam-admin/mixins/timezones"], function (_exports, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayDate = displayDate;
  function displayDate([timestamp, time_zone, linebreak]) {
    if (!timestamp) {
      return "Missing";
    }
    if (timestamp.toDate) {
      timestamp = timestamp.toDate().getTime();
    } else if (timestamp < 2000000000) {
      timestamp = timestamp * 1000;
    }
    return (0, _timezones.getReadableTimestamp)(timestamp, time_zone, linebreak);
  }
  var _default = Ember.Helper.helper(displayDate);
  _exports.default = _default;
});