define("mentorcam-admin/mixins/time-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    timeOptions: Ember.computed(function () {
      return [{
        id: 0,
        display: "12:00 AM"
      }, {
        id: 0.5 * 60,
        display: "12:30 AM"
      }, {
        id: 1 * 60,
        display: "1:00 AM"
      }, {
        id: 1.5 * 60,
        display: "1:30 AM"
      }, {
        id: 2 * 60,
        display: "2:00 AM"
      }, {
        id: 2.5 * 60,
        display: "2:30 AM"
      }, {
        id: 3 * 60,
        display: "3:00 AM"
      }, {
        id: 3.5 * 60,
        display: "3:30 AM"
      }, {
        id: 4 * 60,
        display: "4:00 AM"
      }, {
        id: 4.5 * 60,
        display: "4:30 AM"
      }, {
        id: 5 * 60,
        display: "5:00 AM"
      }, {
        id: 5.5 * 60,
        display: "5:30 AM"
      }, {
        id: 6 * 60,
        display: "6:00 AM"
      }, {
        id: 6.5 * 60,
        display: "6:30 AM"
      }, {
        id: 7 * 60,
        display: "7:00 AM"
      }, {
        id: 7.5 * 60,
        display: "7:30 AM"
      }, {
        id: 8 * 60,
        display: "8:00 AM"
      }, {
        id: 8.5 * 60,
        display: "8:30 AM"
      }, {
        id: 9 * 60,
        display: "9:00 AM"
      }, {
        id: 9.5 * 60,
        display: "9:30 AM"
      }, {
        id: 10 * 60,
        display: "10:00 AM"
      }, {
        id: 10.5 * 60,
        display: "10:30 AM"
      }, {
        id: 11 * 60,
        display: "11:00 AM"
      }, {
        id: 11.5 * 60,
        display: "11:30 AM"
      }, {
        id: 12 * 60,
        display: "12:00 PM"
      }, {
        id: 12.5 * 60,
        display: "12:30 PM"
      }, {
        id: 13 * 60,
        display: "1:00 PM"
      }, {
        id: 13.5 * 60,
        display: "1:30 PM"
      }, {
        id: 14 * 60,
        display: "2:00 PM"
      }, {
        id: 14.5 * 60,
        display: "2:30 PM"
      }, {
        id: 15 * 60,
        display: "3:00 PM"
      }, {
        id: 15.5 * 60,
        display: "3:30 PM"
      }, {
        id: 16 * 60,
        display: "4:00 PM"
      }, {
        id: 16.5 * 60,
        display: "4:30 PM"
      }, {
        id: 17 * 60,
        display: "5:00 PM"
      }, {
        id: 17.5 * 60,
        display: "5:30 PM"
      }, {
        id: 18 * 60,
        display: "6:00 PM"
      }, {
        id: 18.5 * 60,
        display: "6:30 PM"
      }, {
        id: 19 * 60,
        display: "7:00 PM"
      }, {
        id: 19.5 * 60,
        display: "7:30 PM"
      }, {
        id: 20 * 60,
        display: "8:00 PM"
      }, {
        id: 20.5 * 60,
        display: "8:30 PM"
      }, {
        id: 21 * 60,
        display: "9:00 PM"
      }, {
        id: 21.5 * 60,
        display: "9:30 PM"
      }, {
        id: 22 * 60,
        display: "10:00 PM"
      }, {
        id: 22.5 * 60,
        display: "10:30 PM"
      }, {
        id: 23 * 60,
        display: "11:00 PM"
      }, {
        id: 23.5 * 60,
        display: "11:30 PM"
      }, {
        id: 24 * 60,
        display: "12:00 AM"
      }];
    })
  });
  _exports.default = _default;
});