define("mentorcam-admin/components/shared/edit/display-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-profile'],
    tagName: 'span',
    store: Ember.inject.service(),
    model: Ember.computed('modelName', 'row', 'store', function () {
      return this.store.findRecord(this.modelName, this.row);
    })
  });
  _exports.default = _default;
});