define("mentorcam-admin/components/user-profiles/list", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['user-profiles', 'w-3']
  });
  _exports.default = _default;
});