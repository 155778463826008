define("mentorcam-admin/templates/authenticated/user-profiles/modal/edit/broadcast-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mCkN8Kvn",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-profiles/edit/broadcast-messages\",[],[[\"@model\",\"@return\"],[[34,0],\"authenticated.user-profiles.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/user-profiles/modal/edit/broadcast-messages.hbs"
    }
  });
  _exports.default = _default;
});