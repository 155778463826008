define("mentorcam-admin/routes/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      if (this.get('session.isAuthenticated') === false) {
        transition.abort();
        this.transitionTo('login');
      }
    },
    async model() {
      return this.user.setup();
    },
    afterModel(model, transition) {
      if (transition.targetName === 'authenticated.index') {
        if (this.get('user.model.isSEO')) {
          this.transitionTo('authenticated.content-articles');
        } else if (this.get('user.model.isFinance')) {
          this.transitionTo('authenticated.content-articles');
        } else if (this.get('user.model.isReport')) {
          this.transitionTo('authenticated.content-articles');
        } else if (this.get('user.model.isSuperAdmin')) {
          this.transitionTo('authenticated.users', {
            queryParams: {
              is_admin: false,
              page: 1
            }
          });
        } else {
          this.transitionTo('authenticated.traction');
        }
      }
    }
  });
  _exports.default = _default;
});