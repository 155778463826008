define("mentorcam-admin/templates/components/shared/complete-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZQMq/c7r",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"28px\"],[14,\"height\",\"28px\"],[14,\"viewBox\",\"0 0 28 28\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n    \"],[10,\"g\"],[14,1,\"Components\"],[14,\"stroke\",\"none\"],[14,\"stroke-width\",\"1\"],[14,\"fill\",\"none\"],[14,\"fill-rule\",\"evenodd\"],[12],[2,\"\\n        \"],[10,\"g\"],[14,1,\"clock-icon\"],[12],[2,\"\\n            \"],[10,\"circle\"],[14,1,\"Oval-4\"],[14,\"fill\",\"#000000\"],[14,\"cx\",\"14\"],[14,\"cy\",\"14\"],[14,\"r\",\"14\"],[12],[13],[2,\"\\n            \"],[10,\"path\"],[14,\"d\",\"M11,19 C10.4477153,19 10,18.5522847 10,18 C10,17.4477153 10.4477153,17 11,17 L15,17 L15,8 C15,7.44771525 15.4477153,7 16,7 C16.5522847,7 17,7.44771525 17,8 L17,18 L17,18 C17,18.5128358 16.6139598,18.9355072 16.1166211,18.9932723 L16,19 L16,19 L11,19 Z\"],[14,1,\"Combined-Shape\"],[14,\"fill\",\"#FFFFFF\"],[14,\"transform\",\"translate(13.500000, 13.000000) rotate(30.000000) translate(-13.500000, -13.000000) \"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/complete-icon.hbs"
    }
  });
  _exports.default = _default;
});