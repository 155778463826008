define("mentorcam-admin/models/user-profile-availability", ["exports", "mentorcam-ember-shared/models/user-profile-availability"], function (_exports, _userProfileAvailability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _userProfileAvailability.default.extend({
    timestamp: Ember.computed('list', function () {
      if (this.list && this.list[0]) {
        return this.list[0];
      } else {
        return null;
      }
    }),
    lastTimestamp: Ember.computed('list.length', function () {
      if (this.list) {
        return this.list[this.list.length - 1];
      } else {
        return null;
      }
    })
  });
  _exports.default = _default;
});