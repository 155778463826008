define("mentorcam-admin/components/topics/list-user-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-profile'],
    classNameBindings: ['hidden'],
    tagName: 'span',
    store: Ember.inject.service(),
    model: Ember.computed('userProfile', 'store', function () {
      return this.store.findRecord('user-profile', this.userProfile);
    }),
    hidden: Ember.computed('model.visibility.length', function () {
      if (this.get('model.visibility.length') === 0) {
        return false;
      } else {
        return true;
      }
    })
  });
  _exports.default = _default;
});