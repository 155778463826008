define("mentorcam-admin/models/subscription", ["exports", "mentorcam-ember-shared/models/subscription", "mentorcam-admin/mixins/device-display", "mentorcam-admin/mixins/session-display"], function (_exports, _subscription, _deviceDisplay, _sessionDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _subscription.default.extend(_deviceDisplay.default, _sessionDisplay.default, {
    shouldCallsBeScheduled: Ember.computed('calls', 'session.fulfilled_at', 'session.timestamp', function () {
      var ids = [];
      if (this.get('session.fulfilled_at')) {
        ids.push(...this.get('session.fulfilled_at'));
      }
      if (this.get('session.timestamp')) {
        ids.push(...this.get('session.timestamp'));
      }
      ids = [...new Set(ids)];
      return this.get('calls') - ids.length;
    })
  });
  _exports.default = _default;
});