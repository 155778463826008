define("mentorcam-admin/components/shared/csv-input", ["exports", "ember-lib/mixins/childview", "ember-lib/mixins/shake", "papaparse", "ember-lib/mixins/validators", "moment"], function (_exports, _childview, _shake, _papaparse, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_childview.default, _shake.default, _validators.default, {
    classNames: ['inputs', 'file-input-firebase', 'image-input-firebase'],
    classNameBindings: ['invalid', 'isValid:valid'],
    actions: {
      csvAdded(file) {
        const self = this;
        _papaparse.default.parse(file, {
          complete: function (results) {
            self.process(results);
          },
          error: function (error, file, inputElem, reason) {
            console.error(error);
            //Bugsnag.notify(error)
          }
        });
      }
    },

    process({
      data
    }) {
      if (this.complete) this.complete(data);
    },
    isValid: Ember.computed('model.id', function () {
      return true;
    }),
    validate() {
      this.set('invalid', !this.get('isValid'));
    }
  });
  _exports.default = _default;
});