define("mentorcam-admin/components/report/edit", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['report', 'activity'],
    firebase: Ember.inject.service(),
    session: Ember.inject.service(),
    data: 'loading',
    actions: {
      async process(namespace, model) {
        this.set('data.' + namespace + '.loading', true);
        const ids = this.get('data.' + namespace + '.ids');
        const list = await Promise.all(ids.map(async id => {
          try {
            return this.store.findRecord(model, id).then(async model => {
              return model.get('name');
            });
          } catch (error) {
            return id;
          }
        }));
        this.set('data.' + namespace + '.loading', false);
        this.set('data.' + namespace + '.list', list);
      }
    },
    async getReport() {
      try {
        const {
          data
        } = await this.firebase.functions().httpsCallable('getReport')({
          user: '1',
          'start': this.get('model.identifier').split('/')[0],
          'end': this.get('model.identifier').split('/')[1]
        });
        if (!data.status) {
          if (this.get('isDestroyed') !== true) this.set('data', 'error');
        } else {
          if (this.get('isDestroyed') !== true) this.set('data', data.data);
        }
      } catch (error) {
        if (this.get('isDestroyed') !== true) this.set('data', 'error');
      }
    },
    async didInsertElement() {
      await this.getReport();
    }
  });
  _exports.default = _default;
});