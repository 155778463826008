define("mentorcam-admin/routes/authenticated/user-profile-availabilities", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    modelName: 'user-profile-availability'
  });
  _exports.default = _default;
});