define("mentorcam-admin/services/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    initiated: false,
    initiate() {
      if (!this.initiated) {

        //var head = document.getElementsByTagName('head')[0];
        /*
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = "https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/js/medium-editor.min.js";
        head.appendChild(script);
          var linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('media', 'screen');
        linkElement.setAttribute('charset', 'utf-8');
        linkElement.setAttribute('href', 'https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/medium-editor.min.css');
        head.appendChild(linkElement);
        */
      }
    }
  });
  _exports.default = _default;
});