define("mentorcam-admin/components/user-profiles/edit/metadata", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['user-profiles', 'metadata'],
    modelName: "user-profiles",
    didInsertElement() {
      if (!this.get('model.metadata')) {
        this.set('model.metadata', {});
      }
    },
    actions: {
      create(platform) {
        this.set('model.metadata.' + platform, {
          url: "https://"
        });
      },
      disable(platform) {
        const metadata = this.get('model.metadata');
        delete metadata[platform];
        this.set('model.metadata.' + platform, metadata);
      }
    }
  });
  _exports.default = _default;
});