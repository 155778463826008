define("mentorcam-admin/templates/components/header/seo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "h6uQVY8u",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/branding\",[],[[\"@typo\"],[false]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"elevation\"],[15,\"visible\",[34,0]],[12],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"menu left\"],[12],[2,\"\\n\\n  \"],[10,\"li\"],[14,0,\"main\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"title\"],[12],[10,\"span\"],[12],[2,\"Content\"],[13],[2,\"\\n      \"],[8,\"header/arrow\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"ul\"],[14,0,\"submenu\"],[12],[2,\"\\n\\n      \"],[10,\"li\"],[14,0,\"sub-li\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\"],[\"authenticated.content-articles\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"span\"],[12],[2,\"Articles\"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"menu right\"],[12],[2,\"\\n\\n  \"],[10,\"li\"],[14,0,\"main\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[35,1,[\"model\",\"email\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"visible\",\"user\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/header/seo.hbs"
    }
  });
  _exports.default = _default;
});