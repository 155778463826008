define("mentorcam-admin/components/users/edit/send-push-notification", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/public-user-profiles"], function (_exports, _action, _publicUserProfiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_publicUserProfiles.default, {
    classNames: ['conversations', 'send-push-notification'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    //sendPushNotification({ "user-profile": "Ct5xVppGgiekOlbv8IQaFm0seuJ2", "title": "this", "body": "gfdgf", "metadata": { "type":"user-profile", "id": "no1cmIzgMeXB9KBNIiGMFLF8SoK2" } })
    metadataTypes: Ember.computed(function () {
      return [{
        display: "None",
        id: "none"
      }, {
        display: "user-profile",
        id: "user-profile"
      }, {
        display: "message",
        id: "message"
      }];
    }),
    async operation() {
      const input = {
        'user-profile': this.get('model.id'),
        title: this.title,
        body: this.body
      };
      if (this.metadata_type === "user-profile") {
        input.metadata = {
          type: this.metadata_type,
          id: this.userProfile.id
        };
      } else if (this.metadata_type === "message") {
        input.metadata = {
          type: this.metadata_type,
          id: this.message
        };
      }
      console.log(input);
      return this.firebase.functions().httpsCallable('sendPushNotification')(input);
    },
    navigate() {
      this.router.transitionTo("authenticated.users.modal.edit");
    }
  });
  _exports.default = _default;
});