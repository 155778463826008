define("mentorcam-admin/components/shared/edit/filters", ["exports", "mentorcam-admin/components/shared/edit/list-manager"], function (_exports, _listManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _listManager.default.extend({
    models: Ember.computed('store.filters.model', function () {
      return this.store.peekAll('filter');
    })
  });
  _exports.default = _default;
});