define("mentorcam-admin/routes/authenticated/user-profiles", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    queryParams: {
      is_public: {
        refreshModel: true
      },
      is_deleted: {
        refreshModel: true
      },
      is_approved: {
        refreshModel: true
      },
      view: {
        refreshModel: true
      }
    },
    model(params) {
      delete params.view;
      return this.store.query('user-profile', this.transcodeParams(params));
    }
  });
  _exports.default = _default;
});