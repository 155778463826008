define("mentorcam-admin/routes/authenticated/report/modal/edit", ["exports", "mentorcam-admin/routes/authenticated/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    autoreset: false,
    model({
      id
    }) {
      return {
        identifier: id
      };
    }
  });
  _exports.default = _default;
});