define("mentorcam-admin/controllers/authenticated/calls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: {
      page: {
        type: "number"
      },
      is_fulfilled: {
        type: "boolean"
      },
      recipient: {
        type: "string"
      },
      owner: {
        type: "string"
      },
      subscription: {
        type: "string"
      },
      conversation: {
        type: "string"
      },
      is_cancelled: {
        type: "boolean"
      },
      timestamp: {
        type: "string"
      },
      view: {
        type: "string"
      }
    }
  });
  _exports.default = _default;
});