define("mentorcam-admin/templates/components/shared/switcher-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dZhDH3dJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"inputs/text-input\",[],[[\"@lead\",\"@disabled\",\"@placeholder\",\"@value\"],[[34,2],[34,3],[34,4],[30,[36,7],[[35,0],[35,6],[35,5]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[18,1,null],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,8],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"shared/switcher\",[],[[\"@value\",\"@change\"],[[34,0],[30,[36,1],[[32,0],\"change\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"action\",\"lead\",\"disabled\",\"placeholder\",\"no\",\"yes\",\"status\",\"unless\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/switcher-input.hbs"
    }
  });
  _exports.default = _default;
});