define("mentorcam-admin/components/calls/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/timezones", "mentorcam-admin/mixins/admins", "mentorcam-admin/mixins/duration-options"], function (_exports, _edit, _timezones, _admins, _durationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_admins.default, _durationOptions.default, {
    classNames: ['calls', 'edit'],
    modelName: "calls",
    firebase: Ember.inject.service(),
    remainingHours: Ember.computed(function () {
      const array = [{
        id: 1,
        display: '1 hour'
      }, {
        id: 8,
        display: '8 hours'
      }, {
        id: 72,
        display: '72 hours'
      }];
      return array;
    }),
    async didInsertElement() {
      this._super();
      const array = await Promise.all([this.store.findRecord('user-profile', this.get('model.owner.id')), this.store.findRecord('user-profile', this.get('model.recipient.id'))]);
      if (!this.get('isDestroyed')) {
        this.set('userProfiles', array);
      }
    },
    async createPayment() {
      if (this.creatingPayment) return;
      this.set('creatingPayment', true);
      try {
        await this.firebase.functions().httpsCallable('createPayment')({
          'call': this.get('model.id')
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.set('creatingPayment', false);
      }
    },
    async cancel(type) {
      this.set('model.is_cancelled', false);
      await this.model.save();
    },
    /*
    async trackCall(type) {
          if ( this.trackingCall ) return;
        this.set('trackingCall', true);
          try {
              const input = {
                call: this.model.get('id'),
                type: type
            };
              if ( this.elapsed_days && type === 'elapsed_days' ) {
                  input.elapsed_days = Number(this.elapsed_days);
              } else if ( this.remaining_hours && type === 'remaining_hours' ) {
                  input.remaining_hours = Number(this.remaining_hours);
              }
              if ( this.is_test ) {
                input.is_test = true;
                input.admin = this.admin.id;
            }
              const { data } = await this.firebase.functions().httpsCallable('trackCall')(input);
              if ( data.status ) {
                  this.set('trackingCallComplete', true);
              } else {
                  throw data;
              }
          } catch ( error ) {
              console.error(error);
            this.set('trackingCallError', true);
          } finally {
              this.set('trackingCall', false);
          }
      },
    */
    transform(model) {
      if (model.get("timestamp")) {
        model.set("timestamp", model.get('timestamp'));
      }
    }
  });
  _exports.default = _default;
});