define("mentorcam-admin/components/payouts/add", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/public-user-profiles"], function (_exports, _action, _publicUserProfiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_publicUserProfiles.default, {
    classNames: ['payouts', 'add', 'column'],
    firebase: Ember.inject.service(),
    actions: {
      async onOwnerChange(event, value) {
        this.fetching = true;
        Ember.run.later(this, async function () {
          const {
            data
          } = await this.firebase.functions().httpsCallable('getUserPayouts')({
            'user-profile': this.get('owner.id')
          });
          this.fetching = false;
          this.set('data', data);
        });
      }
    },
    async operation() {
      return this.firebase.functions().httpsCallable('requestPayout')({
        'user-profile': this.get('owner.id')
      });
    },
    navigate() {
      this.router.transitionTo("authenticated.payouts");
    }
  });
  _exports.default = _default;
});