define("mentorcam-admin/models/calendar-event", ["exports", "mentorcam-ember-shared/models/calendar-event"], function (_exports, _calendarEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _calendarEvent.default.extend({
    display: Ember.computed('id', function () {
      return this.id;
    })
  });
  _exports.default = _default;
});