define("mentorcam-admin/mixins/admins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    async didInsertElement() {
      const admins = await this.store.query('user', {
        backgroundReload: false,
        reload: true,
        queryId: true,
        filter(reference) {
          return reference.where('is_admin', "==", true);
        }
      });
      if (!this.get('isDestroyed')) {
        this.set('admins', admins);
      }
    }
  });
  _exports.default = _default;
});