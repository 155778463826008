define("mentorcam-admin/templates/authenticated/conversations/modal/edit/create-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g4ioO1CQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"calls/create\",[],[[\"@destination\",\"@owner\",\"@recipient\",\"@conversation\"],[\"authenticated.conversations.modal.edit\",[34,0,[\"owner\"]],[34,0,[\"recipient\"]],[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/conversations/modal/edit/create-call.hbs"
    }
  });
  _exports.default = _default;
});