define("mentorcam-admin/components/user-profiles/sync-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-profiles', 'sync-item', 'row'],
    classNameBindings: ['hidden'],
    init() {
      this._super();
      if (this.data) {
        if (this.shouldTitle) {
          if (this.data.title) {
            if (this.data.title !== this.model.title) {
              this.title = true;
            }
          }
        }
        if (this.shouldCredentials) {
          if (this.data.credentials) {
            if (this.data.credentials !== this.model.credentials) {
              this.credentials = true;
            }
          }
        }
        if (this.shouldBullets) {
          if (this.data.bullets) {
            if (this.data.bullets !== this.model.bullets) {
              this.bullets = true;
            }
          }
        }
        if (this.shouldBiography) {
          if (this.data.biography) {
            if (this.data.biography !== this.model.biography) {
              this.biography = true;
            }
          }
        }
      }
    },
    actions: {
      async update(namespace) {
        this.set('model.' + namespace, this.get('data.' + namespace));
        this.set('processing_' + namespace, true);
        await this.model.save();
        this.set('processing_' + namespace, false);
        this.set(namespace, false);
      }
    },
    data: Ember.computed('data', 'list', 'model.username', 'row.id', function () {
      const username = this.get('model.username');
      const row = this.list.find(item => {
        if (item[5] === username) {
          return true;
        } else {
          return false;
        }
      });
      if (row) {
        return {
          name: row[0],
          title: row[1],
          credentials: row[2],
          biography: row[3],
          bullets: row[4],
          username: row[5]
        };
      } else {
        return null;
      }
    }),
    hidden: Ember.computed('credentials', 'data', 'title', 'bullets', 'biography', function () {
      if (this.data) {
        if (this.title || this.credentials || this.bullets || this.biography) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
  });
  _exports.default = _default;
});