define("mentorcam-admin/components/conversations/list", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['conversations', 'w-2'],
    async setSessionCounts() {
      if (this.settingSessionCounts) return;
      this.set('settingSessionCounts', true);
      try {
        await Promise.all(this.model.map(function (item) {
          item.set('session_count', 1);
          return item.save();
        }));
        this.set('settingSessionCountsComplete', true);
      } catch (error) {
        this.set('settingSessionCountsError', true);
        console.error(error);
      } finally {
        this.set('settingSessionCounts', false);
      }
    }
  });
  _exports.default = _default;
});