define("mentorcam-admin/templates/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mFydlU4Z",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"model\",\"isSuperAdmin\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"header/header\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"model\",\"isReport\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"header/report\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"model\",\"isFinance\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"header/finance\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"model\",\"isSEO\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"header/seo\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"header/empty\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"user\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated.hbs"
    }
  });
  _exports.default = _default;
});