define("mentorcam-admin/templates/authenticated/promo-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xS6W+Joc",
    "block": "{\"symbols\":[],\"statements\":[[8,\"promo-codes/list\",[],[[\"@model\",\"@edit\",\"@add\"],[[34,0],\"authenticated.promo-codes.modal.edit\",\"authenticated.promo-codes.modal.add\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/promo-codes.hbs"
    }
  });
  _exports.default = _default;
});