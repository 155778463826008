define("mentorcam-admin/components/users/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['users', 'edit'],
    modelName: "users",
    firebase: Ember.inject.service(),
    async disconnectCalendar() {
      if (this.disconnectingCalendar) return;
      this.set('disconnectingCalendar', true);
      try {
        await this.firebase.functions().httpsCallable('disconnectCalendar')({
          'user-profile': this.get('model.id')
        });
        this.set('disconnectingCalendar', true);
      } catch (error) {
        this.set('disconnectingCalendar', true);
        console.error(error);
      } finally {
        this.set('disconnectingCalendar', false);
      }
    },
    async upgradeUserProfile() {
      if (this.upgradingUserProfile) return;
      this.set('upgradingUserProfile', true);
      try {
        const model = await this.get('model.userProfile');
        model.set('is_public', true);
        await model.save();
        this.set('upgradingUserProfile', true);
      } catch (error) {
        this.set('upgradingUserProfile', true);
        console.error(error);
      } finally {
        this.set('upgradingUserProfile', false);
      }
    },
    async invalidatePromoCodes() {
      if (this.invalidatingPromoCodes) return;
      this.set('invalidatingPromoCodes', true);
      try {
        const result = await this.firebase.functions().httpsCallable('invalidatePromoCodes')({
          'user': this.get('model.id')
        });
        this.set('invalidatingPromoCodesComplete', true);
      } catch (error) {
        this.set('invalidatingPromoCodesError', true);
        console.error(error);
      } finally {
        this.set('invalidatingPromoCodes', false);
      }
    }
  });
  _exports.default = _default;
});