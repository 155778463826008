define("mentorcam-admin/components/user-profiles/edit/sales", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['user-profiles', 'analytics', 'column', 'sales'],
    store: Ember.inject.service(),
    firebase: Ember.inject.service(),
    async getUserCharges() {
      const {
        data
      } = await this.firebase.functions().httpsCallable('getUserCharges')({
        'user-profile': this.get('model.id')
      });
      this.set('data', data);
    },
    async getList() {
      const firestore = await this.firebase.functions().firestore();
      const id = this.get('model.id');
      const recipient = firestore.collection("user-profiles").doc(id);
      const list = await this.store.query('charge', {
        filter: {
          recipient: recipient
        }
      });
      this.set('list', list);
      return list;
    },
    async didInsertElement() {
      await Promise.all([this.getUserCharges(), this.getList()]);
    }
  });
  _exports.default = _default;
});