define("mentorcam-admin/mixins/transcode-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    transcodeParams(params, sort, extension) {
      let limit = null;
      if (params.page) {
        limit = Number(params.page) * this.pagination;
      } else {
        sort = false;
      }
      if (params.email) {
        sort = false;
      }
      delete params.page;
      for (let key in params) {
        if (params[key] === undefined) {
          delete params[key];
        } else if (params[key] === "true") {
          params[key] = true;
        } else if (params[key] === "false") {
          params[key] = false;
        } else if (key === 'payout') {
          params[key] = this.firebase.firestore().collection('payouts').doc(params[key]);
        } else if (key === 'subscription') {
          params[key] = this.firebase.firestore().collection('subscriptions').doc(params[key]);
        } else if (key === 'conversation') {
          params[key] = this.firebase.firestore().collection('conversations').doc(params[key]);
        } else if (key === 'owner' || key === 'recipient') {
          params[key] = this.firebase.firestore().collection('user-profiles').doc(params[key]);
        }
      }
      return {
        backgroundReload: false,
        reload: true,
        queryId: true,
        filter(reference) {
          Object.keys(params).forEach(name => {
            const value = params[name];
            if (name !== "user_profiles") {
              reference = reference.where(name, "==", value);
            } else {
              reference = reference.where(name, "array-contains", value);
            }
          });
          if (limit) {
            reference = reference.limit(limit);
          }
          if (sort === true) {
            reference = reference.orderBy('created_at', 'desc');
          }
          if (typeof sort === "object") {
            reference = reference.orderBy(sort.param, sort.by);
          }
          if (extension) {
            reference = reference.where(extension.query, extension.operator, extension.value);
          }
          return reference;
        }
      };
    }
  });
  _exports.default = _default;
});