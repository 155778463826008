define("mentorcam-admin/adapters/application", ["exports", "ember-cloud-firestore-adapter/adapters/cloud-firestore"], function (_exports, _cloudFirestore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _cloudFirestore.default.extend({

    //referenceKeyName: 'mentorcam',
  });
  _exports.default = _default;
});