define("mentorcam-admin/initializers/video-player", ["exports", "mentorcam-admin/services/video-player"], function (_exports, _videoPlayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    app.register('service:videoPlayer', _videoPlayer.default);
    app.inject('component', 'videoPlayer', 'service:videoPlayer');
  }
  var _default = {
    name: 'videoPlayer',
    initialize: initialize
  };
  _exports.default = _default;
});