define("mentorcam-admin/components/emails/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/email-templates"], function (_exports, _edit, _emailTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_emailTemplates.default, {
    classNames: ['emails', 'edit'],
    modelName: "emails"
  });
  _exports.default = _default;
});