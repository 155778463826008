define("mentorcam-admin/components/users/edit/send-email", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/public-user-profiles"], function (_exports, _action, _publicUserProfiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_publicUserProfiles.default, {
    classNames: ['conversations', 'send-email'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    async operation() {
      return this.firebase.functions().httpsCallable('sendEmail')({
        'user-profile': this.get('model.id'),
        from: this.userProfile.id,
        subject: this.subject,
        params: {
          text: this.text
        }
      });
    },
    navigate() {
      this.router.transitionTo("authenticated.users.modal.edit");
    }
  });
  _exports.default = _default;
});