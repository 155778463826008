define("mentorcam-admin/models/filter", ["exports", "mentorcam-ember-shared/models/filter"], function (_exports, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _filter.default.extend({
    display: Ember.computed.reads('name')
  });
  _exports.default = _default;
});