define("mentorcam-admin/components/user-profiles/edit/availability", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/time-options"], function (_exports, _edit, _timeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_timeOptions.default, {
    classNames: ['user-profiles', 'availability'],
    init() {
      this._super();
      if (this.get('model.availability.singleton') !== true || this.get('model.availability.singleton') !== false) {
        this.set('model.availability.singleton', false);
      }
      this.set('availability', Ember.Object.create(this.model.get('availability')));
    },
    actions: {
      delete(namespace, index) {
        if (index === 0) {
          this.set('availability.' + namespace + ".enabled", false);
          return;
        }
        const availability = this.availability;
        availability[namespace].items.splice(index, 1);
        this.set("availability", Ember.Object.create(availability));
      },
      add(namespace) {
        const availability = this.availability;
        this.availability[namespace].items.push({
          start: 560,
          end: 960
        });
        this.set("availability", Ember.Object.create(availability));
      }
    },
    transform(model) {
      const availability = this.availability;
      const list = ["monday.items", "tuesday.items", "wednesday.items", "thursday.items", "friday.items", "saturday.items", "sunday.items"];
      list.forEach(namespace => {
        if (availability.get(namespace)) {
          availability.get(namespace).forEach(item => {
            item.start = Number(item.start);
            item.end = Number(item.end);
          });
        }
      });
      const object = availability.getProperties("complete", "singleton", "time_zone", 'monday', 'tuesday', 'wednesday', "thursday", "friday", "saturday", "sunday");
      model.set('availability', object);
    }
  });
  _exports.default = _default;
});