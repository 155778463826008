define("mentorcam-admin/components/filters/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['filters'],
    modelName: "filter",
    store: Ember.inject.service(),
    categories: Ember.computed('store.categories.model', function () {
      return this.store.findAll('category');
    }),
    groups: Ember.computed('store.groups.model', function () {
      return this.store.findAll('group');
    }),
    init() {
      this._super();
      this.metadata = {
        filter: this.get('model.id')
      };
    },
    actions: {}
  });
  _exports.default = _default;
});