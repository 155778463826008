define("mentorcam-admin/templates/components/shared/pause-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SWk3f1jO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"40px\"],[14,\"height\",\"40px\"],[14,\"viewBox\",\"0 0 40 40\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n    \"],[10,\"g\"],[14,\"stroke\",\"none\"],[14,\"stroke-width\",\"1\"],[14,\"fill\",\"none\"],[14,\"fill-rule\",\"evenodd\"],[12],[2,\"\\n        \"],[10,\"circle\"],[14,\"fill-opacity\",\"0.6\"],[14,\"fill\",\"#000000\"],[14,\"cx\",\"20\"],[14,\"cy\",\"20\"],[14,\"r\",\"20\"],[12],[13],[2,\"\\n        \"],[10,\"g\"],[14,\"transform\",\"translate(14.000000, 13.000000)\"],[14,\"fill\",\"#FFFFFF\"],[12],[2,\"\\n            \"],[10,\"rect\"],[14,1,\"Rectangle\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"width\",\"4\"],[14,\"height\",\"14\"],[14,\"rx\",\"1\"],[12],[13],[2,\"\\n            \"],[10,\"rect\"],[14,1,\"Rectangle\"],[14,\"x\",\"8\"],[14,\"y\",\"0\"],[14,\"width\",\"4\"],[14,\"height\",\"14\"],[14,\"rx\",\"1\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/pause-button.hbs"
    }
  });
  _exports.default = _default;
});