define("mentorcam-admin/helpers/appsflyer-deep-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appsflyerDeepLink = appsflyerDeepLink;
  _exports.default = void 0;
  function appsflyerDeepLink([id]) {
    return 'https://appsflyer.mentor.cam/du6n?deep_link_value=' + id;
  }
  var _default = Ember.Helper.helper(appsflyerDeepLink);
  _exports.default = _default;
});