define("mentorcam-admin/models/traction", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    identifier: Ember.computed('start_date', 'end_date', function () {
      return (0, _moment.default)(this.start_date).format('YYYY-MM-DD') + "/" + (0, _moment.default)(this.end_date).format('YYYY-MM-DD');
    }),
    start_date: (0, _model.attr)('date'),
    end_date: (0, _model.attr)('date')
  });
  _exports.default = _default;
});