define("mentorcam-admin/templates/components/shared/content/rich-text-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UjTyrEMF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"editor\"],[15,1,[31,[\"editor-\",[32,0,[\"element\",\"id\"]]]]],[12],[2,\"\\n    \"],[2,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"initial\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/content/rich-text-editor.hbs"
    }
  });
  _exports.default = _default;
});