define("mentorcam-admin/components/groups/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['groups'],
    modelName: "group"
  });
  _exports.default = _default;
});