define("mentorcam-admin/templates/components/shared/multiple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VoBtTveP",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"shared/switcher-input\",[],[[\"@classNames\",\"@placeholder\",\"@value\",\"@yes\",\"@no\",\"@change\"],[\"row\",[32,1,[\"name\"]],[32,1,[\"selected\"]],[34,0],[34,1],[30,[36,2],[[32,0],\"change\"],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"yes\",\"no\",\"action\",\"computedModels\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/multiple-select.hbs"
    }
  });
  _exports.default = _default;
});