define("mentorcam-admin/templates/authenticated/push-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "M02xcFR8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"push-notifications/list\",[],[[\"@page\",\"@model\",\"@edit\",\"@add\"],[[32,0,[\"page\"]],[34,0],\"authenticated.push-notifications.modal.edit\",\"authenticated.push-notifications.modal.add\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/push-notifications.hbs"
    }
  });
  _exports.default = _default;
});