define("mentorcam-admin/templates/authenticated/conversations/modal/process-drips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "D1KuYBWk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"conversations/process-drips\",[],[[\"@destination\"],[\"authenticated.conversations\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/conversations/modal/process-drips.hbs"
    }
  });
  _exports.default = _default;
});