define("mentorcam-admin/templates/components/users/edit/newsletter-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cRy7q9JW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[35,6,[\"name\"]]],[2,\" (\"],[1,[35,1,[\"index\"]]],[2,\")\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Sending...\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Error\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Limit reached\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Sent it!\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,1,[\"status\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n            \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"send\"],null],[12],[2,\"Send again\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n            \"],[11,\"p\"],[24,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[4,[38,0],[[32,0],\"send\"],null],[12],[2,\"Send\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"row\",\"if\",\"complete\",\"limit\",\"error\",\"model\",\"sending\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/users/edit/newsletter-row.hbs"
    }
  });
  _exports.default = _default;
});