define("mentorcam-admin/controllers/authenticated/texts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: {
      page: {
        type: "number"
      },
      owner: {
        type: "string"
      },
      has_response: {
        type: "boolean"
      }
    }
  });
  _exports.default = _default;
});