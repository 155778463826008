define("mentorcam-admin/routes/authenticated/search-logs", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    queryParams: {
      interaction: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },
    model(params) {
      return this.store.query('search-log', this.transcodeParams(params, true));
    }
  });
  _exports.default = _default;
});