define("mentorcam-admin/mixins/exports", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    firebase: Ember.inject.service(),
    init() {
      this._super();
      var self = this;
      firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
        self.set('idToken', idToken);
      }).catch(function (error) {
        console.error(error);
      });
    },
    getExport(namespace, range) {
      const start_date = range.split("/")[0];
      const end_date = range.split("/")[1];
      if (this.idToken) {
        return _emberGetConfig.default.cloudFunctions + "/" + namespace + "?token=" + this.idToken + "&start_date=" + start_date + "&end_date=" + end_date;
      } else {
        return null;
      }
    }
  });
  _exports.default = _default;
});