define("mentorcam-admin/components/shared/list", ["exports", "mentorcam-admin/components/shared/section", "ember-get-config"], function (_exports, _section, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend({
    classNames: ['shared', 'list'],
    add: true,
    length: true,
    owner: true,
    actions: {
      async executeDelete() {
        if (_emberGetConfig.default.environment === "development") {
          if (this.processing) return;
          this.set('processing', true);
          const promises = this.model.map(function (item) {
            return item.destroyRecord();
          });
          await Promise.all(promises);
          this.set('processing', false);
        }
      }
    },
    isDevelopment: Ember.computed('page', function () {
      return _emberGetConfig.default.environment === "development";
    }),
    nextPage: Ember.computed('page', function () {
      let page = this.page;
      if (!page) {
        page = 1;
      }
      return Number(page) + 1;
    }),
    filteredModels: Ember.computed.filter('model', function (item) {
      return !item.get('isNew');
    })
  });
  _exports.default = _default;
});