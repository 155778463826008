define("mentorcam-admin/components/user-profiles/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['user-profiles', 'edit'],
    modelName: "user-profiles",
    firebase: Ember.inject.service(),
    videoCallPrices: Ember.computed(function () {
      return [{
        id: 50,
        display: "$50"
      }, {
        id: 100,
        display: "$100"
      }, {
        id: 150,
        display: "$150"
      }, {
        id: 200,
        display: "$200"
      }, {
        id: 250,
        display: "$250"
      }, {
        id: 300,
        display: "$300"
      }, {
        id: 350,
        display: "$350"
      }, {
        id: 400,
        display: "$400"
      }, {
        id: 500,
        display: "$500"
      }];
    }),
    cannotBeApproved: Ember.computed('missingContent', 'model.user.activity.calendar_events.calendar.total', 'model.user.calendar.complete', 'model.user.hasBrokenCalendar', function () {
      if (!this.get('model.user.calendar.complete')) {
        return true;
      }
      if (this.get('model.user.hasBrokenCalendar') || this.get('missingContent')) {
        return true;
      }
      return false;
    }),
    missingContent: Ember.computed('model.{biography,username,bullets,title,credentials}', function () {
      if (this.get('model.biography') && this.get('model.username') && this.get('model.bullets') && this.get('model.title') && this.get('model.credentials')) {
        return false;
      } else {
        return true;
      }
    }),
    transform(model) {
      if (model.get('pricing.live_call.item_price')) {
        model.set('pricing.live_call.item_price', Number(model.get('pricing.live_call.item_price')));
      }
    },
    init() {
      this._super();
      this.metadata = {
        owner: this.get('model.id')
      };
      if (this.get('model.pricing.live_call.item_price')) {
        const price = String(Math.round(Number(this.get('model.pricing.live_call.item_price'))));
        console.log(price);

        //this.set('model.pricing.live_call.item_price',price);
      }
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    async getMediaAssets() {
      if (this.gettingMediaAssets) return;
      this.set('gettingMediaAssets', true);
      try {
        const result = await this.firebase.functions().httpsCallable('getMediaAssets')({
          'user-profile': this.get('model.id')
        });
        this.set('mediaAssets', result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('gettingMediaAssets', false);
      }
    },
    async fixNotifications() {
      if (this.fixingNotifications) return;
      this.set('fixingNotifications', true);
      try {
        const result = await this.firebase.functions().httpsCallable('fixUserNotifications')({
          'user': this.get('model.id')
        });
        console.log(result.status);

        //this.set('mediaAssets', result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('fixingNotifications', false);
      }
    },
    async setUserAvailability() {
      if (this.settingUserAvailability) return;
      this.set('settingUserAvailability', true);
      try {
        const result = await this.firebase.functions().httpsCallable('setUserAvailability')({
          'user': this.get('model.id')
        });

        //console.log(result.status);

        //this.set('mediaAssets', result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('settingUserAvailability', false);
      }
    },
    async syncGoogleCalendarEvents() {
      if (this.syncingGoogleCalendarEvents) return;
      this.set('syncingGoogleCalendarEvents', true);
      try {
        const result = await this.firebase.functions().httpsCallable('syncGoogleCalendarEvents')({
          'user': this.get('model.id')
        });
        this.set('syncingGoogleCalendarEvents', result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('syncingGoogleCalendarEvents', false);
      }
    },
    async deleteMessages() {
      if (this.deletingMessages) return;
      this.set('deletingMessages', true);
      try {
        const result = await this.firebase.functions().httpsCallable('deleteMessages')({
          'user-profile': this.get('model.id')
        });
        this.set('deleteMessageResult', result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('deletingMessages', false);
      }
    },
    async getMessageReport() {
      if (this.gettingMessageReport) return;
      this.set('gettingMessageReport', true);
      try {
        const {
          data
        } = await this.firebase.functions().httpsCallable('getMessageReport')({
          'user-profile': this.get('model.id')
        });
        this.set('messageReport', data);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('gettingMessageReport', false);
      }
    },
    async sendMessageReport() {
      if (this.sendingMessageReport) return;
      this.set('sendingMessageReport', true);
      try {
        await this.firebase.functions().httpsCallable('sendMessageReport')({
          'user-profile': this.get('model.id')
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.set('sendingMessageReport', false);
      }
    },
    async triggerConnections() {
      if (this.triggeringConnections) return;
      this.set('triggeringConnections', true);
      try {
        await this.firebase.functions().httpsCallable('triggerConnections')({
          'user-profile': this.get('model.id')
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.set('triggeringConnections', false);
      }
    },
    async createUserDynamicLink() {
      if (this.creatingLink) return;
      this.set('creatingLink', true);
      try {
        await this.firebase.functions().httpsCallable('createUserDynamicLink')({
          'user-profile': this.get('model.id')
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.set('creatingLink', false);
      }
    }
  });
  _exports.default = _default;
});