define("mentorcam-admin/components/user-profiles/sync", ["exports", "mentorcam-admin/components/user-profiles/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['user-profiles', 'sync'],
    shouldTitle: true,
    shouldCredentials: true,
    shouldBullets: true,
    shouldBiography: true,
    init() {
      this._super();
    },
    actions: {
      parse(data) {
        this.set('list', data);
      }
    }
  });
  _exports.default = _default;
});