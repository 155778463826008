define("mentorcam-admin/components/user-profiles/edit/bank", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['user-profiles', 'edit', 'bank'],
    modelName: "users",
    firebase: Ember.inject.service(),
    init() {
      this._super();
      if (!this.get('model.bank')) {
        this.set('model.bank', {});
      }
    },
    transform(model) {
      if (model.get('bank.type')) {
        if (!model.get('bank.name')) {
          model.set('bank.type', null);
        }
      }
    },
    federalClasses: Ember.computed(function () {
      return [{
        id: 'Individual',
        display: "Individual"
      }, {
        id: 'Limited liability company (LLC)',
        display: "Limited liability company (LLC)"
      }, {
        id: 'C Corporation',
        display: "C Corporation"
      }, {
        id: 'S Corporation',
        display: "S Corporation"
      }, {
        id: 'Partnerships',
        display: "Partnerships"
      }, {
        id: 'Corporation - Nonprofit',
        display: "Corporation - Nonprofit"
      }];
    }),
    bankTypes: Ember.computed(function () {
      return [{
        id: 'us',
        display: "U.S."
      }, {
        id: 'international',
        display: "International"
      }];
    })
  });
  _exports.default = _default;
});