define("mentorcam-admin/mixins/conversation-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    conversationTypes: Ember.computed(function () {
      return [{
        display: 'To purchase again',
        id: "repurchase"
      }, {
        display: 'To make first purchase',
        id: "prepurchase"
      }];
    })
  });
  _exports.default = _default;
});