define("mentorcam-admin/components/calls/cancel", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['calls', 'cancel'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    async didInsertElement() {
      this._super();
      const list = await Promise.all([this.store.findRecord('user-profile', this.get('model.owner.id')), this.store.findRecord('user-profile', this.get('model.recipient.id'))]);
      if (this.isDestroyed === false) {
        this.set('selectableUserProfiles', list);
      }
    },
    async operation() {
      return this.firebase.functions().httpsCallable('cancelCall')({
        'user-profile': this.userProfile.id,
        call: this.model.id
      });
    },
    navigate() {
      this.router.transitionTo(this.destination, this.model.id);
    }
  });
  _exports.default = _default;
});