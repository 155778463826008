define("mentorcam-admin/components/subscriptions/list", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['subscriptions', 'w-2'],
    firebase: Ember.inject.service(),
    is_cancelled_list: Ember.computed.filter('model.@each.is_cancelled', function (item) {
      return item.get('is_cancelled');
    }),
    not_cancelled_list: Ember.computed.filter('model.@each.is_cancelled', function (item) {
      return !item.get('is_cancelled');
    }),
    sort(a, b) {
      return a.get('current_period_end') - b.get('current_period_end');
    },
    async syncSubscriptions() {
      if (this.syncingSubscriptions) return;
      this.set('syncingSubscriptions', true);
      try {
        const result = await this.firebase.functions().httpsCallable('syncSubscriptions')({});
      } catch (error) {
        console.error(error);
      } finally {
        this.set('syncingSubscriptions', false);
      }
    }
  });
  _exports.default = _default;
});