define("mentorcam-admin/components/login", ["exports", "mentorcam-admin/components/shared/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend({
    classNames: ['shared', 'section', 'login'],
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      async complete() {
        if (this.processing) {
          return;
        }
        this.set('processing', true);
        try {
          await this.session.authenticate('authenticator:firebase', auth => {
            return auth.signInWithEmailAndPassword(this.email, this.password);
          });

          // this.router.transitionTo('authenticated');
        } catch (error) {
          this.set('error', error);
          throw error;
        } finally {
          this.set('processing', false);
        }
      },
      focusIn() {
        this.set('error', false);
      }
    }
  });
  _exports.default = _default;
});