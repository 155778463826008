define("mentorcam-admin/templates/authenticated/session-logs/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DRhLykA6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/modal\",[],[[\"@return\"],[\"authenticated.session-logs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/session-logs/modal.hbs"
    }
  });
  _exports.default = _default;
});