define("mentorcam-admin/templates/authenticated/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AaVsH5ar",
    "block": "{\"symbols\":[],\"statements\":[[8,\"messages/list\",[],[[\"@conversation\",\"@owner\",\"@triggered\",\"@page\",\"@model\",\"@edit\",\"@add\"],[[34,0],[34,1],[34,2],[32,0,[\"page\"]],[34,3],\"authenticated.messages.modal.edit\",\"authenticated.messages.modal.add\"]],null],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"conversation\",\"owner\",\"triggered\",\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/messages.hbs"
    }
  });
  _exports.default = _default;
});