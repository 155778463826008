define("mentorcam-admin/templates/authenticated/calendar-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ioTS2v/e",
    "block": "{\"symbols\":[],\"statements\":[[8,\"calendar-events/list\",[],[[\"@owner\",\"@page\",\"@model\",\"@edit\"],[[34,0],[32,0,[\"page\"]],[34,1],\"authenticated.calendar-events.modal.edit\"]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"owner\",\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/calendar-events.hbs"
    }
  });
  _exports.default = _default;
});