define("mentorcam-admin/utils/parser", ["exports", "ember-cloud-firestore-adapter/utils/parser"], function (_exports, _parser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _parser.default;
    }
  });
});