define("mentorcam-admin/routes/application", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    session: Ember.inject.service(),
    init() {
      this._super();
      console.log('version: ' + _emberGetConfig.default.version);
    },
    async model() {
      return Promise.all([this.store.findAll('country'), this.store.findAll('topic'), this.store.findAll('group'), this.store.findAll('category'), this.store.findAll('filter'), this.store.findAll('logo')]);
    },
    afterModel(model, transition) {
      if (transition.targetName === "application.index") {
        if (this.session.get('isAuthenticated')) {
          this.transitionTo('authenticated');
        } else {
          this.transitionTo('login');
        }
      }
    }
  });
  _exports.default = _default;
});