define("mentorcam-admin/components/payouts/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['payouts', 'edit'],
    modelName: "payouts",
    firebase: Ember.inject.service(),
    async trackPayout() {
      if (this.tracking) return;
      this.set('tracking', true);
      try {
        await this.firebase.functions().httpsCallable('trackPayout')({
          'payout': this.get('model.id')
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.set('tracking', false);
      }
    }
  });
  _exports.default = _default;
});