define("mentorcam-admin/components/content-articles/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/read-time"], function (_exports, _edit, _readTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_readTime.default, {
    classNames: ['content-articles', 'edit'],
    modelName: "content-articles",
    init() {
      this._super();
      this.metadata = {
        content_article: this.get('model.id')
      };
    },
    transform(model) {
      if (model.get('read_time')) {
        model.set('read_time', Number(model.get('read_time')));
      }
    }
  });
  _exports.default = _default;
});