define("mentorcam-admin/templates/authenticated/users/modal/edit/user-profiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "t6vW3fsX",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/edit/user-profiles\",[],[[\"@list\",\"@namespace\",\"@model\",\"@return\",\"@destination\"],[[34,0,[\"user_profiles\"]],\"user_profiles\",[34,0],\"authenticated.users.modal.edit\",\"authenticated.users.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/users/modal/edit/user-profiles.hbs"
    }
  });
  _exports.default = _default;
});