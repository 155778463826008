define("mentorcam-admin/mixins/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    didInsertElement() {
      document.querySelector('body').classList.add("scroll");
    },
    willDestroyElement() {
      document.querySelector('body').classList.remove('scroll');
    }
  });
  _exports.default = _default;
});