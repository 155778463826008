define("mentorcam-admin/components/shared/action", ["exports", "mentorcam-admin/components/shared/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend({
    classNames: ['action', 'edit'],
    router: Ember.inject.service(),
    classNameBindings: ['column'],
    store: Ember.inject.service(),
    actions: {
      focusIn() {
        this.set('error', null);
      },
      async complete() {
        if (this.get('processing')) return;
        this.set('processing', true);
        try {
          const {
            data
          } = await this.operation();
          if (data.status) {
            this.set('data', data);
          } else {
            console.error(data);
            this.set('error', data);
          }
        } catch (error) {
          console.error(error);
          this.set('error', error);
        }
        this.set('processing', false);
        if (!this.get('error')) {
          this.navigate(this.get('data'));
        }
      }
    },
    navigate() {},
    async operation() {
      return {};
    }
  });
  _exports.default = _default;
});