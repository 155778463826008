define("mentorcam-admin/components/reset-password", ["exports", "mentorcam-admin/components/shared/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend({
    classNames: ['shared', 'section', 'login'],
    router: Ember.inject.service(),
    firebase: Ember.inject.service(),
    actions: {
      async complete() {
        if (this.processing) {
          return;
        }
        this.set('processing', true);
        try {
          const {
            data
          } = await this.firebase.functions().httpsCallable('forgotPassword')({
            email: this.email
          });
          if (data.status) {
            this.set('complete', true);
          } else {
            throw new Error(data);
          }
        } catch (error) {
          this.set('error', error);
          throw error;
        } finally {
          this.set('processing', false);
        }
      },
      focusIn() {
        this.set('error', false);
      }
    }
  });
  _exports.default = _default;
});