define("mentorcam-admin/components/testimonials/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['testimonials', 'edit'],
    modelName: "testimonial",
    async didInsertElement() {
      const contentArticles = await this.store.query('content-article', {
        backgroundReload: false,
        reload: true,
        queryId: true
      });
      if (!this.get('isDestroyed')) {
        this.set('contentArticles', contentArticles);
      }
    },
    init() {
      this._super();
      this.metadata = {
        testimonial: this.get('model.id')
      };
    },
    transform(model) {
      if (!model.get('has_article')) {
        console.log('has_article is false, setting article to null');
        model.set('content_article', null);
      }
    }
  });
  _exports.default = _default;
});