define("mentorcam-admin/mixins/identifier-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    identifierOptions: Ember.computed(function () {
      return [{
        id: 0,
        display: "Mentorcam Starter"
      }, {
        id: 1,
        display: "Mentorcam Pro"
      }, {
        id: 2,
        display: "Mentorcam Premium"
      }];
    })
  });
  _exports.default = _default;
});