define("mentorcam-admin/components/content-articles/list", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['topics', 'w-2'],
    async executeSort() {
      if (this.sorting) return;
      this.set('sorting', true);
      try {
        const objects = this.model.map(function (item) {
          return {
            id: item.get('id'),
            name: item.get('name'),
            item: item
          };
        }).sort(function (a, b) {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        await Promise.all(objects.map(function (item, index) {
          item.item.set('sort_index', index + 1);
          return item.item.save();
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.set('sorting', false);
      }
    }
  });
  _exports.default = _default;
});