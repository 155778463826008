define("mentorcam-admin/templates/authenticated/calls/modal/edit/update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Fz8m7TvQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"calls/update\",[],[[\"@model\",\"@label\",\"@return\",\"@destination\"],[[34,0],[34,0,[\"id\"]],\"authenticated.calls.modal.edit\",\"authenticated.calls.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/calls/modal/edit/update.hbs"
    }
  });
  _exports.default = _default;
});