define("mentorcam-admin/components/logos/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['logos'],
    modelName: "logos",
    typeOptions: Ember.computed(function () {
      return [{
        id: 'image',
        display: 'JPG'
      }, {
        id: 'file',
        display: 'SVG'
      }];
    }),
    init() {
      this._super();
      this.metadata = {};
    }
  });
  _exports.default = _default;
});