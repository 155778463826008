define("mentorcam-admin/components/sign-up", ["exports", "mentorcam-admin/components/shared/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _section.default.extend({
    classNames: ['shared', 'section', 'login'],
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    firebase: Ember.inject.service(),
    actions: {
      async complete() {
        if (this.processing) {
          return;
        }
        this.set('processing', true);
        try {
          await this.session.authenticate('authenticator:firebase', auth => {
            return auth.createUserWithEmailAndPassword(this.email, this.password);
          });
          const id = this.get('session.data.authenticated.user.uid');
          await this.firebase.functions().httpsCallable('createUser')({
            created_on: 'web',
            authentication: 'email',
            id: id,
            first_name: this.first_name,
            last_name: this.last_name
          });
          this.router.transitionTo('authenticated');
        } catch (error) {
          this.set('error', error);
          throw error;
        } finally {
          this.set('processing', false);
        }
      },
      focusIn() {
        this.set('error', false);
      }
    }
  });
  _exports.default = _default;
});