define("mentorcam-admin/models/email", ["exports", "mentorcam-ember-shared/models/email"], function (_exports, _email) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _email.default.extend({
    displayParams: Ember.computed('params', function () {
      const params = this.params;
      if (params) {
        return Object.keys(params).map(function (key) {
          return {
            key: key,
            value: params[key]
          };
        });
      } else {
        return null;
      }
    }),
    displayMetadata: Ember.computed('metadata', function () {
      const metadata = this.metadata;
      if (metadata) {
        return Object.keys(metadata).map(function (key) {
          return {
            key: key,
            value: metadata[key]
          };
        });
      } else {
        return null;
      }
    })
  });
  _exports.default = _default;
});