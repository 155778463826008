define("mentorcam-admin/components/messages/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/admins"], function (_exports, _edit, _admins) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_admins.default, {
    classNames: ['messages'],
    modelName: "messages",
    firebase: Ember.inject.service(),
    init() {
      this._super();
      this.metadata = {
        owner: this.get('model.owner.id'),
        recipient: this.get('model.recipient.id'),
        message: this.get('model.id')
      };
    },
    elapsedDays: Ember.computed(function () {
      const array = [{
        id: 0,
        display: 'Immediate'
      }, {
        id: 1,
        display: '1 day'
      }, {
        id: 2,
        display: '2 days'
      }, {
        id: 7,
        display: '7 days'
      }, {
        id: 14,
        display: '14 days'
      }];
      return array;
    }),
    async chainMessages() {
      if (this.chainingMessages) return;
      this.set('chainingMessages', true);
      try {
        await this.firebase.functions().httpsCallable('chainMessages')({
          conversation: this.get('model.conversation.id')
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.set('chainingMessages', false);
      }
    },
    async getDownloadUrl() {
      if (this.gettingDownloadUrl) return;
      this.set('gettingDownloadUrl', true);
      try {
        const {
          data
        } = await this.firebase.functions().httpsCallable('getDownloadUrl')({
          message: this.model.get('id')
        });
        if (data.status) {
          this.set('gettingDownloadUrlComplete', true);
          console.log(data.url);
        } else {
          throw data;
        }
      } catch (error) {
        console.error(error);
        this.set('gettingDownloadUrlError', true);
      } finally {
        this.set('gettingDownloadUrl', false);
      }
    },
    async trackMessage() {
      if (this.trackingMessage) return;
      this.set('trackingMessage', true);
      try {
        const input = {
          call: this.model.get('id'),
          elapsed_days: Number(this.elapsed_days)
        };
        if (this.is_test) {
          input.is_test = true;
          input.admin = this.admin.id;
        }
        const {
          data
        } = await this.firebase.functions().httpsCallable('trackMessage')(input);
        if (data.status) {
          this.set('trackingMessageComplete', true);
        } else {
          throw data;
        }
      } catch (error) {
        console.error(error);
        this.set('trackingMessageError', true);
      } finally {
        this.set('trackingMessage', false);
      }
    },
    async processVideo() {
      if (this.processingVideo) return;
      this.set('processingVideo', true);
      try {
        const video = this.get('model.video');
        video.message = this.get('model.id');
        const {
          data
        } = await this.firebase.functions().httpsCallable('processVideo')(video);
        if (data.id && data.video && data.cloudinary) {
          console.log('success');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.set('processingVideo', false);
      }
    }
  });
  _exports.default = _default;
});