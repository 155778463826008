define("mentorcam-admin/helpers/percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.percentage = percentage;
  function percentage([input]) {
    if (!input) {
      input = 0;
    }
    return Math.round(input * 100) + "%";
  }
  var _default = Ember.Helper.helper(percentage);
  _exports.default = _default;
});