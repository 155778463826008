define("mentorcam-admin/helpers/timezone", ["exports", "mentorcam-admin/mixins/timezones"], function (_exports, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timezone = timezone;
  function timezone([input]) {
    const name = (0, _timezones.getName)(input);
    if (name) {
      return name;
    }
    return "Invalid (" + input + ")";
  }
  var _default = Ember.Helper.helper(timezone);
  _exports.default = _default;
});