define("mentorcam-admin/templates/authenticated/calls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3D6ztb0e",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5],\"recipient\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \\n      \"],[8,\"calls/user-profiles\",[],[[\"@model\",\"@view\",\"@edit\"],[[34,3],[34,5],\"authenticated.calls.modal.edit\"]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[35,5],\"owner\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n      \"],[8,\"calls/user-profiles\",[],[[\"@model\",\"@view\",\"@edit\"],[[34,3],[34,5],\"authenticated.calls.modal.edit\"]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n      \"],[8,\"calls/list\",[],[[\"@conversation\",\"@owner\",\"@recipient\",\"@page\",\"@is_cancelled\",\"@model\",\"@timestamp\",\"@edit\"],[[34,0],[34,1],[34,2],[32,0,[\"page\"]],[32,0,[\"is_cancelled\"]],[34,3],[34,4],\"authenticated.calls.modal.edit\"]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,9],[[30,[36,8],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"conversation\",\"owner\",\"recipient\",\"model\",\"timestamp\",\"view\",\"eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/calls.hbs"
    }
  });
  _exports.default = _default;
});