define("mentorcam-admin/components/conversations/edit/send-video", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['conversations', 'send-video'],
    firebase: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    init() {
      this._super();
      this.metadata = {
        owner: this.get('model.owner.id'),
        recipient: this.get('model.recipient.id'),
        message: this.get('model.id')
      };
    },
    async operation() {
      const input = this.get('model.video');
      input.created_on = "optimized";
      const {
        data
      } = await this.firebase.functions().httpsCallable('processVideo')(input);
      const response = await this.firebase.functions().httpsCallable('sendVideo')({
        message: this.get('model.id'),
        video: data,
        conversation: this.get('model.conversation.id')
      });
      this.get('model').unloadRecord();
      const model = await this.store.findRecord('message', response.data.message);
      this.set('model', model);
      return response;
    },
    navigate() {
      this.router.transitionTo("authenticated.conversations.modal.edit");
    }
  });
  _exports.default = _default;
});