define("mentorcam-admin/components/calls/list", ["exports", "mentorcam-admin/components/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    classNames: ['calls', 'w-3'],
    sortedModel: Ember.computed.sort('model', ['timestamp', 'cancelled_at'], function (a, b) {
      if (this.is_cancelled === true) {
        return b.get('cancelled_at') - a.get('cancelled_at') > 0 ? 1 : -1;
      }
      if (this.timestamp === 'past') {
        return b.get('timestamp') - a.get('timestamp') > 0 ? 1 : -1;
      } else {
        return a.get('timestamp') - b.get('timestamp') > 0 ? 1 : -1;
      }
    })
  });
  _exports.default = _default;
});