define("mentorcam-admin/templates/authenticated/user-profiles/modal/edit/availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tq38C9M0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-profiles/edit/availability\",[],[[\"@model\",\"@return\",\"@destination\"],[[34,0],\"authenticated.user-profiles.modal.edit\",\"authenticated.user-profiles.modal.edit\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/user-profiles/modal/edit/availability.hbs"
    }
  });
  _exports.default = _default;
});