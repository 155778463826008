define("mentorcam-admin/mixins/admin-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    adminRoles: Ember.computed(function () {
      return [{
        id: "SEO_ADMIN",
        display: 'SEO Admin (level 1)'
      }, {
        id: "FINANCE_ADMIN",
        display: 'Finance Admin (level 2)'
      }, {
        id: "REPORT_ADMIN",
        display: 'Report Admin  (level 3)'
      }, {
        id: "SUPER_ADMIN",
        display: 'Super Admin (level 4)'
      }];
    })
  });
  _exports.default = _default;
});