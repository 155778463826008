define("mentorcam-admin/routes/authenticated/subscriptions/modal/edit/user-profiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    /*
      model() {
          const model = this.modelFor('authenticated.subscriptions.modal.edit');
          return {
          model: model,
          user_profiles: null
        }
        },
      */

    renderTemplate() {
      this.render({
        into: 'authenticated.subscriptions.modal'
      });
    }
  });
  _exports.default = _default;
});