define("mentorcam-admin/templates/components/inputs/file-droplet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "i35fZnE2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,0]]]],\"hasEval\":false,\"upvars\":[\"preview\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/inputs/file-droplet.hbs"
    }
  });
  _exports.default = _default;
});