define("mentorcam-admin/components/calls/create", ["exports", "mentorcam-admin/components/calls/update", "mentorcam-admin/mixins/duration-options"], function (_exports, _update, _durationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _update.default.extend(_durationOptions.default, {
    time: 780,
    quantity: 30,
    autoGenerate: true,
    async didInsertElement() {
      this._super();
      if (this.get('model.recipient.id')) {
        const list = await Promise.all([this.store.findRecord('user-profile', this.get('model.owner.id')), this.store.findRecord('user-profile', this.get('model.recipient.id'))]);
        if (this.isDestroyed === false) {
          this.set('selectableUserProfiles', list);
        }
      }
    },
    async operation() {
      //var link = this.link;
      //if ( !this.autoGenerate ) link = null;

      return this.firebase.functions().httpsCallable('createCall')({
        'user-profile': this.get('conversation.owner.id'),
        conversation: this.conversation.id,
        timestamp: this.calculatedTimestamp,
        quantity: this.quantity
      });
    },
    navigate(data) {
      this.router.transitionTo("authenticated.calls", {
        queryParams: {
          conversation: this.conversation.id
        }
      });
    }
  });
  _exports.default = _default;
});