define("mentorcam-admin/models/text", ["exports", "mentorcam-ember-shared/models/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _text.default.extend({
    displayService: Ember.computed('twilio', 'nexmo', function () {
      if (this.twilio && this.nexmo) {
        return "Twilio + Nexmo";
      } else if (this.twilio) {
        return "Twilio";
      } else if (this.nexmo) {
        return "Nexmo";
      }
    }),
    displayResponse: Ember.computed('response', 'has_response', function () {
      if (this.has_response) {
        return this.get('response.Body');
      } else {
        return null;
      }
    })
  });
  _exports.default = _default;
});