define("mentorcam-admin/mixins/session-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    sessionDisplay: Ember.computed('created_at', 'session_log.created_at', function () {
      if (this.get('session_log') && this.get('session_log.created_at')) {
        return Math.round((this.get('created_at').getTime() - this.get('session_log.created_at').getTime()) / 1000);
      } else {
        return null;
      }
    })
  });
  _exports.default = _default;
});