define("mentorcam-admin/components/conversations/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['conversations', 'edit'],
    modelName: "conversations",
    firebase: Ember.inject.service(),
    async chainMessages() {
      if (this.chainingMessages) return;
      this.set('chainingMessages', true);
      try {
        await this.firebase.functions().httpsCallable('chainMessages')({
          conversation: this.get('model.id')
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.set('chainingMessages', false);
      }
    }
  });
  _exports.default = _default;
});