define("mentorcam-admin/routes/authenticated/shared/list", ["exports", "mentorcam-admin/mixins/transcode-params"], function (_exports, _transcodeParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_transcodeParams.default, {
    pagination: 100,
    firebase: Ember.inject.service(),
    model() {
      return this.store.findAll(this.modelName, {
        reload: true,
        backgroundReload: false
      });
    }
  });
  _exports.default = _default;
});