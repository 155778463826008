define("mentorcam-admin/components/calls/update", ["exports", "mentorcam-admin/components/shared/action", "mentorcam-admin/mixins/time-options", "mentorcam-admin/mixins/timezones", "moment-timezone"], function (_exports, _action, _timeOptions, _timezones, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend(_timeOptions.default, _timezones.default, {
    classNames: ['calls', 'update'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    defaultTimezone: "America/Los_Angeles",
    init() {
      this._super();
      const date = new _momentTimezone.default.tz(this.get('model.timestamp'), this.defaultTimezone);
      this.set("date", date.clone().startOf('day').toDate().getTime());
      const minutes = date.hours() * 60 + date.minutes();
      this.set('time', minutes);
    },
    dateOptions: Ember.computed('defaultTimezone', function () {
      const array = [];
      const start = _momentTimezone.default.tz(new Date(), this.defaultTimezone).startOf('day').add({
        day: -20
      });
      for (let index = 0; index < 60; index++) {
        let date = _momentTimezone.default.tz(start.toDate(), this.defaultTimezone).add({
          days: index
        });
        array.push({
          display: date.format('ddd, MMM D, YYYY'),
          id: date.toDate().getTime()
        });
      }
      return array;
    }),
    async operation() {
      return this.firebase.functions().httpsCallable('updateCall')({
        'user-profile': this.get('model.owner.id'),
        timestamp: this.calculatedTimestamp,
        call: this.model.id
      });
    },
    calculatedTimestamp: Ember.computed('date', 'defaultTimezone', 'time', function () {
      return _momentTimezone.default.tz(this.date, this.defaultTimezone).startOf('day').add({
        minutes: this.time
      }).toDate().getTime();
    }),
    navigate() {
      this.router.transitionTo(this.destination, this.model.id);
    }
  });
  _exports.default = _default;
});