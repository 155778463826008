define("mentorcam-admin/components/shared/edit/content-articles-row", ["exports", "mentorcam-admin/components/shared/edit/list-item"], function (_exports, _listItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _listItem.default.extend({
    modelName: "content-article"
  });
  _exports.default = _default;
});