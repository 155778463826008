define("mentorcam-admin/components/user-profile-availabilities/edit", ["exports", "mentorcam-admin/components/shared/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend({
    classNames: ['user-profile-availabilities', 'edit'],
    modelName: "user-profile-availabilities",
    firebase: Ember.inject.service()
  });
  _exports.default = _default;
});