define("mentorcam-admin/templates/components/user-profiles/edit/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UOBL8uFl",
    "block": "{\"symbols\":[\"submit\",\"registerView\",\"unregisterView\",\"isValid\",\"isInvalid\"],\"statements\":[[8,\"inputs/form\",[],[[\"@classNames\",\"@disabled\",\"@complete\"],[\"shared panel\",[34,2],[30,[36,3],[[32,0],\"complete\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navigation\"],[12],[2,\"\\n\\n        \"],[10,\"h1\"],[12],[10,\"strong\"],[12],[2,\"Sales\"],[13],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[8,\"shared/edit/section\",[],[[\"@title\",\"@classNames\"],[\"Summary\",\"first\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"row statistics\"],[12],[2,\"\\n                \"],[10,\"p\"],[12],[2,\"Total bills\"],[13],[2,\"\\n                \"],[10,\"h3\"],[12],[1,[30,[36,1],[[35,0,[\"user\",\"analytics\",\"bills\",\"total\"]],\"true\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"row statistics\"],[12],[2,\"\\n                \"],[10,\"p\"],[12],[2,\"Total payments\"],[13],[2,\"\\n                \"],[10,\"h3\"],[12],[1,[30,[36,1],[[35,0,[\"user\",\"analytics\",\"payments\",\"total\"]],\"true\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n        \"],[8,\"shared/spinner\",[],[[\"@medium\",\"@black\"],[true,true]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"link-to\",[],[[\"@route\",\"@classNames\"],[[34,6],\"shared row button black large cancel\"]],[[\"default\"],[{\"statements\":[[10,\"span\"],[12],[2,\"Cancel\"],[13]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1,2,3,4,5]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"currency\",\"processing\",\"action\",\"data\",\"if\",\"return\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/user-profiles/edit/sales.hbs"
    }
  });
  _exports.default = _default;
});