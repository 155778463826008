define("mentorcam-admin/components/conversations/edit/send-message", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['conversations', 'send-message'],
    firebase: Ember.inject.service(),
    router: Ember.inject.service(),
    async operation() {
      const input = {
        conversation: this.get('model.id'),
        text: this.text
      };
      return this.firebase.functions().httpsCallable('sendMessage')(input);
    },
    navigate() {
      this.router.transitionTo("authenticated.conversations.modal.edit");
    }
  });
  _exports.default = _default;
});