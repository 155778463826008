define("mentorcam-admin/helpers/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currency = currency;
  _exports.default = void 0;
  function currency([amount, rounded]) {
    if (!amount) {
      amount = 0;
    }
    if (rounded) {
      return '$' + Math.round(Number(amount)).toLocaleString();
    } else {
      return '$' + Number(amount).toFixed(2).toLocaleString();
    }
  }
  var _default = Ember.Helper.helper(currency);
  _exports.default = _default;
});