define("mentorcam-admin/models/call", ["exports", "mentorcam-ember-shared/models/call", "mentorcam-admin/mixins/device-display", "mentorcam-admin/mixins/display-attributions"], function (_exports, _call, _deviceDisplay, _displayAttributions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _call.default.extend(_deviceDisplay.default, _displayAttributions.default, {
    display: Ember.computed('id', function () {
      return this.id;
    }),
    timestampDate: Ember.computed('timestamp', function () {
      return new Date(this.timestamp);
    }),
    hasLink: Ember.computed('has_link', 'link', function () {
      return this.has_link === true && this.link;
    }),
    isLegacy: Ember.computed('calendar', 'calendar.id', function () {
      return this.calendar && this.calendar.id ? true : false;
    }),
    calculatedTimestamp: Ember.computed('timestamp', function () {
      return this.timestamp;
    }),
    displayDuration: Ember.computed('attendance.duration', function () {
      const duration = this.get('attendance.duration') || 0;
      return Math.round(duration / 60);
    }),
    isPast: Ember.computed('timestamp', function () {
      return Date.now() > this.timestamp;
    })
  });
  _exports.default = _default;
});