define("mentorcam-admin/components/shared/table", ["exports", "ember-lib/components/shared/sort"], function (_exports, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sort.default.extend({
    classNames: ['shared', 'table', 'panel'],
    classNameBindings: ['isPinned'],
    isPinned: false,
    init() {
      this._super(...arguments);
      this.set('boundOnscroll', this.get('onscroll').bind(this));
    },
    willInsertElement() {
      this._super(...arguments);
      window.addEventListener('scroll', this.get('boundOnscroll'), false);
    },
    willDestroyElement() {
      this._super(...arguments);
      window.removeEventListener('scroll', this.get('boundOnscroll'), false);
    },
    onscroll() {
      var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if (scrollTop < 116) {
        this.set('isPinned', false);
      } else {
        this.set('isPinned', true);
      }
    }
  });
  _exports.default = _default;
});