define("mentorcam-admin/services/hlsfactory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    async setup() {
      return window.Hls;
    }
  });
  _exports.default = _default;
});