define("mentorcam-admin/helpers/timespent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timespent = timespent;
  function timespent([duration]) {
    if (duration) {
      if (duration < 60) {
        return Math.round(duration) + " seconds";
      } else if (duration < 3600) {
        return Math.round(duration / 60) + " minutes";
      } else {
        return Math.round(duration / 3600) + " hours";
      }
    } else {
      return null;
    }
  }
  var _default = Ember.Helper.helper(timespent);
  _exports.default = _default;
});