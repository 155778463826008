define("mentorcam-admin/templates/components/shared/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WH0IOnXf",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"source\"],[15,\"src\",[34,0]],[14,4,\"application/x-mpegURL\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hls\",\"if\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/shared/video.hbs"
    }
  });
  _exports.default = _default;
});