define("mentorcam-admin/mixins/display-attributions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    displayAttributions: Ember.computed('marketing', function () {
      const marketing = this.marketing;
      if (marketing) {
        return Object.keys(marketing).map(function (key) {
          return {
            key: key,
            value: marketing[key]
          };
        });
      } else {
        return null;
      }
    })
  });
  _exports.default = _default;
});