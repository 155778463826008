define("mentorcam-admin/templates/components/inputs/file-input-firebase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/kI/WHqS",
    "block": "{\"symbols\":[\"preview\"],\"statements\":[[10,\"div\"],[14,0,\"lead\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[10,\"h4\"],[12],[10,\"span\"],[12],[1,[34,3]],[13],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"h4\"],[12],[10,\"span\"],[12],[1,[34,0]],[13],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\" \"],[10,\"span\"],[14,0,\"star\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[13],[2,\"\\n\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"inputs/file-droplet\",[],[[\"@file\",\"@accept\",\"@added\"],[[34,5],[34,6],[30,[36,7],[[32,0],\"added\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[10,\"button\"],[15,\"processing\",[34,8]],[14,0,\"shared button shadow black small\"],[14,5,\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[14,4,\"button\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"Upload\"],[13],[2,\"\\n\"],[6,[37,2],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"shared/spinner\",[],[[\"@medium\",\"@white\"],[false,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"required\",\"if\",\"model\",\"isValid\",\"file\",\"accept\",\"action\",\"processing\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/components/inputs/file-input-firebase.hbs"
    }
  });
  _exports.default = _default;
});