define("mentorcam-admin/components/subscriptions/edit", ["exports", "mentorcam-admin/components/shared/edit", "mentorcam-admin/mixins/identifier-options", "mentorcam-admin/mixins/duration-options", "mentorcam-admin/mixins/admins", "mentorcam-admin/mixins/call-options"], function (_exports, _edit, _identifierOptions, _durationOptions, _admins, _callOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _edit.default.extend(_callOptions.default, _identifierOptions.default, _durationOptions.default, _admins.default, {
    classNames: ['subscriptions', 'edit'],
    modelName: "subscriptions",
    firebase: Ember.inject.service(),
    async trackSubscription() {
      if (this.trackingSubscription) return;
      this.set('trackingSubscription', true);
      try {
        const input = {
          subscription: this.model.get('id')
        };
        if (this.is_test) {
          input.is_test = true;
          input.admin = this.admin.id;
        }
        const {
          data
        } = await this.firebase.functions().httpsCallable('trackSubscription')(input);
        if (data.status) {
          this.set('trackingSubscriptionComplete', true);
        } else {
          throw data;
        }
      } catch (error) {
        console.error(error);
        this.set('trackingSubscriptionError', true);
      } finally {
        this.set('trackingSubscription', false);
      }
    },
    async migrate() {
      if (this.migrating) return;
      this.set('migrating', true);
      try {
        const id = this.get('model.recipient.id');
        this.set('model.user_profiles', [id]);
        await this.get('model').save();
      } catch (error) {
        console.error(error);
      } finally {
        this.set('migrating', false);
      }
    },
    async syncSubscription() {
      if (this.syncingSubscription) return;
      this.set('syncingSubscription', true);
      try {
        const result = await this.firebase.functions().httpsCallable('syncSubscription')({
          'subscription': this.get('model.id')
        });

        //console.log(result.status);
      } catch (error) {
        console.error(error);
      } finally {
        this.set('syncingSubscription', false);
      }
    }
  });
  _exports.default = _default;
});