define("mentorcam-admin/templates/authenticated/message-drafts/modal/edit/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0+8bJ9i1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shared/delete\",[],[[\"@model\",\"@label\",\"@return\",\"@destination\"],[[34,0],[34,0,[\"id\"]],\"authenticated.message-drafts.modal.edit\",\"authenticated.message-drafts\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "mentorcam-admin/templates/authenticated/message-drafts/modal/edit/delete.hbs"
    }
  });
  _exports.default = _default;
});