define("mentorcam-admin/router", ["exports", "mentorcam-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    headData: Ember.inject.service(),
    setTitle(title) {
      this.headData.set('title', title);
    }
  });
  Router.map(function () {
    this.route('login');
    this.route('sign-up');
    this.route('reset-password');
    this.route('authenticated', {
      path: ''
    }, function () {
      this.route('dashboard');
      this.route('report', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          });
          this.route('range');
        });
      });
      this.route('profile', function () {
        this.route('edit');
      });
      this.route('users', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('search');
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('auth');
            this.route('delete');
            this.route('email');
            this.route('transfer');
            this.route('create-conversation');
            this.route('create-subscription');
            this.route('password');
            this.route('delete-card');
            this.route('create-card');
            this.route('newsletters');
            this.route('send-push-notification');
            this.route('send-text');
            this.route('send-email');
            this.route('user-profiles');
          });
        });
      });
      this.route('push-notifications', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('bills', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('leads', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('testimonials', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('payments', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('packages', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('custom-packages', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('programs', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('search-logs', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('referral-leads', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('referral-credits', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('user-profile-views', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('checkout-views', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('message-views', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('conversation-views', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('topic-views', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('session-logs', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('calendar-events', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('user-profiles', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('sync');
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('bank');
            this.route('assets');
            this.route('username');
            this.route('metadata');
            this.route('pricing');
            this.route('availability');
            this.route('sales');
            this.route('logos');
            this.route('broadcast-push-notifications');
            this.route('broadcast-emails');
            this.route('broadcast-texts');
            this.route('broadcast-messages');
            this.route('google-calendar');
            this.route('exchange-calendar');
            this.route('icloud-calendar');
            this.route('office-calendar');
          });
        });
      });
      this.route('user-profile-availabilities', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('conversations', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('create-call');
            this.route('create-charge');
            this.route('send-message');
            this.route('send-video');
            this.route('delete');
          });
        });
      });
      this.route('subscriptions', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('cancel');
            this.route('user-profiles');
          });
        });
      });
      this.route('charges', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('emails', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('texts', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('payment-links', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('user-profiles');
          });
        });
      });
      this.route('promo-codes', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('gift-cards', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('payouts', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('message-drafts', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('stripe-sessions', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('messages', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('assets');
          });
        });
      });
      this.route('calls', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('update');
            this.route('cancel');
          });
        });
      });
      this.route('countries', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('topics', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
            this.route('user-profiles');
            this.route('topics');
            this.route('content');
          });
        });
      });
      this.route('groups', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('categories', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('user-profiles');
            this.route('delete');
            this.route('topics');
            this.route('content');
          });
        });
      });
      this.route('filters', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('user-profiles');
            this.route('delete');
            this.route('topics');
            this.route('content');
          });
        });
      });
      this.route('logos', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('delete');
          });
        });
      });
      this.route('content-articles', function () {
        this.route('modal', {
          path: 'm'
        }, function () {
          this.route('add', {
            path: 'create'
          });
          this.route('edit', {
            path: ':id'
          }, function () {
            this.route('content');
            this.route('delete');
            this.route('topics');
            this.route('categories');
            this.route('filters');
            this.route('user-profiles');
          });
        });
      });
      this.route('logout');
    });
  });
  var _default = Router;
  _exports.default = _default;
});