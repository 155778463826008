define("mentorcam-admin/helpers/geo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.geo = geo;
  function geo([length, status]) {
    if (!length) {
      length = 0;
    }
    if (status) {
      return length !== 0;
    } else {
      return length === 0;
    }
  }
  var _default = Ember.Helper.helper(geo);
  _exports.default = _default;
});