define("mentorcam-admin/mixins/email-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    emailTemplates: Ember.computed(function () {
      return [{
        display: 'Prepurchase 1',
        id: "d-ad2f6dec67da411c9e3e88b15f84c0dd"
      }, {
        display: 'Prepurchase 2',
        id: "d-7dd90696ed6a4dd49615fbfa14016331"
      }, {
        display: 'Message Notification to Mentee',
        id: "d-f5dccd6889a14cd3a7e9375a02cce6a5"
      }, {
        display: 'Message Notification to Mentor (Immediate)',
        id: "d-0facdc15b9b943319d950e1c948c0dc4"
      }, {
        display: 'Message Notification to Mentor (1 day)',
        id: "d-74488df8fe984b00b84c0f07af85e75b"
      }, {
        display: 'Message Notification to Mentor (2+ days)',
        id: "d-cfbce9fcc7d245378494563d33d830c4"
      }];
    })
  });
  _exports.default = _default;
});