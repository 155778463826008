define("mentorcam-admin/helpers/minutes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.minutes = minutes;
  function minutes([time]) {
    if (time < 60) {
      return time + ' minutes';
    } else if (time < 60 * 24) {
      return Math.round(time / 60) + " hours";
    } else {
      return Math.round(time / 60 / 24) + " days";
    }
  }
  var _default = Ember.Helper.helper(minutes);
  _exports.default = _default;
});