define("mentorcam-admin/helpers/program-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.programTypes = programTypes;
  function programTypes([identifier]) {
    const object = {
      accelerator: "Accelerator Prep",
      fundraising: "Fundraising Starter Pack",
      mvp: "From MVP to PMF"
    };
    return object[identifier];
  }
  var _default = Ember.Helper.helper(programTypes);
  _exports.default = _default;
});