define("mentorcam-admin/mixins/call-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    callOptions: Ember.computed(function () {
      const array = [{
        id: 0,
        display: 'No calls'
      }, {
        id: 1,
        display: '1 call'
      }, {
        id: 2,
        display: '2 calls'
      }, {
        id: 3,
        display: '3 calls'
      }, {
        id: 4,
        display: '4 calls'
      }, {
        id: 5,
        display: '5 calls'
      }, {
        id: 6,
        display: '6 calls'
      }, {
        id: 7,
        display: '7 calls'
      }, {
        id: 8,
        display: '8 calls'
      }, {
        id: 9,
        display: '9 calls'
      }, {
        id: 10,
        display: '10 calls'
      }, {
        id: 11,
        display: '11 calls'
      }, {
        id: 12,
        display: '12 calls'
      }, {
        id: 13,
        display: '13 calls'
      }, {
        id: 14,
        display: '14 calls'
      }, {
        id: 15,
        display: '15 calls'
      }];
      return array;
    })
  });
  _exports.default = _default;
});