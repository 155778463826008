define("mentorcam-admin/components/users/edit/send-text", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['conversations', 'send-text'],
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),
    async operation() {
      return this.firebase.functions().httpsCallable('sendText')({
        'user-profile': this.get('model.id'),
        text: this.text
      });
    },
    navigate() {
      this.router.transitionTo("authenticated.users.modal.edit");
    }
  });
  _exports.default = _default;
});