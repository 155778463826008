define("mentorcam-admin/routes/authenticated/logos", ["exports", "mentorcam-admin/routes/authenticated/shared/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _list.default.extend({
    modelName: 'logo'
  });
  _exports.default = _default;
});