define("mentorcam-admin/mixins/device-display", ["exports", "ua-parser-js"], function (_exports, _uaParserJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    deviceDisplay: Ember.computed('browser.metadata.userAgent', 'created_on', 'device.{platform,version}', 'platform', 'version', function () {
      if (this.platform && this.version) {
        return this.getPlatform(this.platform) + " (" + this.version + ")";
      } else if (this.device && this.device.platform && this.device.version) {
        return this.getPlatform(this.device.platform) + " (" + this.device.version + ")";
      } else if (this.browser && this.browser.metadata && this.browser.metadata.userAgent) {
        const parser = new _uaParserJs.default(this.browser.metadata.userAgent);
        return parser.getBrowser().name;
      } else if (this.platform) {
        return this.getPlatform(this.platform);
      } else if (this.created_on) {
        return this.getPlatform(this.created_on);
      } else {
        return null;
      }
    }),
    getPlatform(platform) {
      if (platform === "android") {
        return "Android";
      } else if (platform === 'ios') {
        return "iOS";
      } else if (platform === "web") {
        return "Web";
      } else if (platform === "admin") {
        return "Admin";
      }
    }
  });
  _exports.default = _default;
});