define("mentorcam-admin/components/users/edit/transfer", ["exports", "mentorcam-admin/components/shared/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _action.default.extend({
    classNames: ['users', 'email'],
    firebase: Ember.inject.service(),
    namespace: "calendar",
    namespaceOptions: Ember.computed(function () {
      return [{
        id: "calendar",
        display: "Calendar"
      }, {
        id: "availability",
        display: "Availability"
      }];
    }),
    async operation() {
      return this.firebase.functions().httpsCallable('transferUserData')({
        owner: this.get('model.id'),
        recipient: this.get('recipient'),
        namespace: this.namespace
      });
    },
    navigate() {
      this.get('router').transitionTo("authenticated.users.modal.edit");
    }
  });
  _exports.default = _default;
});