define("mentorcam-admin/mixins/length-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    lengthDisplay: Ember.computed('count', 'length', function () {
      if (this.length && this.count) {
        return this.length;
      } else {
        return this.length;
      }
    })
  });
  _exports.default = _default;
});